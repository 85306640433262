//@ts-nocheck
import _ from "lodash";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { Spin, Card, message, Empty, Flex, Tabs, Modal } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";

import useCommonStore from "stores/useCommon";
import usePackage from "stores/usePackage";
import SuggestionRenovation from "components/dashboard/suggestion-renovation";
import ResultatTab from "components/dashboard/resultat-tab";
import ModalDynamicInformation from "components/dashboard/modal-dynamic-information";
import Package from "components/dashboard/package";
import Fiabilite from "components/dashboard/fiabilite";
import MonDPE from "components/dashboard/mon-dpe";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { ReactComponent as IconLocation } from "assets/images/location.svg";
import { ReactComponent as IconHouse } from "assets/images/house.svg";
import { ReactComponent as IconRuler } from "assets/images/ruler.svg";
import { ReactComponent as IconCalendar } from "assets/images/calendar-2.svg";
import { ReactComponent as IconStair } from "assets/images/stair.svg";
import { ReactComponent as IconEnergy } from "assets/images/energy.svg";
import { ReactComponent as IconTemperature } from "assets/images/temperature.svg";
import { ReactComponent as IconWater } from "assets/images/water.svg";
import { ReactComponent as IconWall } from "assets/images/wall.svg";
import { ReactComponent as IconWind } from "assets/images/wind.svg";
import { ReactComponent as IconIce } from "assets/images/ice.svg";
import { ReactComponent as IconCog } from "assets/images/cog.svg";
import { ReactComponent as IconHash } from "assets/images/hash.svg";
import { ReactComponent as IconMondpe } from "assets/images/mondpe.svg";
import { ReactComponent as IconOldCalendar } from "assets/images/calendar-3.svg";
import { ReactComponent as IconHelp } from "assets/images/help-circle.svg";
import { getUserToken } from "services/storages/userStorage";
import { cognitoUserPool } from "config";

import "styles/home.scss";

function Dashboard() {
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const { setIsShowInsight, setIsLoadedPage, setAlertTop } = useCommonStore();
  const { isAutoOpenInsight, setIsAutoOpenInsight } = usePackage();

  const reliabilityRef = useRef("");

  const { id } = useParams();

  const [dataHomeUploaded, setDataHomeUploaded] = useState(null);
  const [searchDpesMap, setSearchDpesMap] = useState(null);
  const [resultAutocomplete, setResultAutocomplete] = useState<any[]>([]);
  const [resultAutocompleteTitle, setResultAutocompleteTitle] = useState("");
  const [selectedLabelId, setSelectedLabelId] = useState("");
  const [svgFiabilite, setSvgFiabilite] = useState("");
  const [svgGraphePrice, setSvgGraphePrice] = useState("");

  const [tab, setTab] = useState("1");
  const [dataModalFiabilite, setDataModalFiabilite] = useState(null);
  const [isShowModalFiabilite, setIsShowModalFiabilite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: dataFiabilite,
    isFetching: isFetchingFiabilite,
    isSuccess: isSuccessFiabilite,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_DATA_FIABILITE, id],
    queryFn: () => API.mock.getDataFiabilite(id),
    retry: 0,
  });

  const {
    data: dataSuggestion,
    // isFetching: isFetchingSuggestion,
    isSuccess: isSuccessSuggestion,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_SUGGESTION_RENOVATION, id],
    queryFn: () => API.mock.getSuggestionRenovation(String(id)),
    retry: 0,
  });

  const { data: dataDynamicInfo } = useQuery({
    queryKey: [QUERY_KEYS.GET_DYNAMIC_INFORMATION_RESULTAT, id],
    queryFn: () => API.mock.getDynamicInformationResultat(String(id)),
    retry: 0,
  });

  const {
    mutateAsync: mutateUploaded,
    isPending: isPendingUploaded,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (params: any) => API.mock.getHomeUploadData(params),
  });

  const { mutateAsync: mutateChatInit, isPending: isPendingChatInit } =
    useMutation({
      mutationFn: (params: any) => API.mock.chatInit(params),
      retry: 0,
    });

  const { mutateAsync: mutateSearchDpesMap, isPending: isPendingSearchMap } =
    useMutation({
      mutationFn: (params: any) => API.mock.searchDpesMap(params),
      retry: 0,
    });

  const {
    mutateAsync: mutateSVGFiabilite,
    // isPending: isPendingSVGFiabilite
  } = useMutation({
    mutationFn: (params: any) => API.mock.getSVGFiabilite(params),
  });

  const {
    mutateAsync: mutateGraphePrice,
    // isPending: isPendingSVGFiabilite
  } = useMutation({
    mutationFn: (params: any) => API.mock.getGraphePrice(id),
  });

  useEffect(() => {
    loadData();
    return () => {
      setAlertTop(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (location.state?.refresh && id) {
      setIsLoading(true);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location.state?.refresh]);

  useEffect(() => {
    if (isSuccessFiabilite && isSuccessSuggestion && isSuccess) {
      setIsLoadedPage(true);
    }
  }, [isSuccessFiabilite, isSuccessSuggestion, isSuccess, setIsLoadedPage]);

  useEffect(() => {
    if (dataHomeUploaded) {
      setIsLoading(false);
    }
  }, [dataHomeUploaded]);

  const handleToInsight = useCallback(() => {
    setIsShowInsight(true);
  }, [setIsShowInsight]);

  useEffect(() => {
    if (isAutoOpenInsight) {
      const handleOpenChatAI = async () => {
        try {
          await mutateChatInit({
            ref_ademe: id,
            from: "dashboard",
          });
          handleToInsight();
        } catch (e) {
          console.log("error", e);
        }
      };
      setIsAutoOpenInsight(false);
      handleOpenChatAI();
    }
  }, [
    handleToInsight,
    id,
    isAutoOpenInsight,
    mutateChatInit,
    setIsAutoOpenInsight,
  ]);

  const loadData = async () => {
    try {
      const result = await mutateUploaded({
        id,
      });
      setDataHomeUploaded(result);
      setAlertTop(result?.banner || null);

      const [resultSvgFiabilite, resultGraphePrice] = await Promise.all([
        mutateSVGFiabilite({
          ref_ademe: id,
        }),
        mutateGraphePrice(id),
      ]);

      setSvgFiabilite(resultSvgFiabilite);
      setSvgGraphePrice(resultGraphePrice);

      const resultSearchDpes = await mutateSearchDpesMap({
        ref_ademe: id,
        geometry: {
          coordinates: [
            _.get(result, "geo_data.lng"),
            _.get(result, "geo_data.lat"),
          ],
        },
      });

      setResultAutocomplete(resultSearchDpes?.resp_addresses || []);
      setSearchDpesMap(
        _.uniqBy(
          resultSearchDpes?.surrounding_addresses.map((item: any) => ({
            label: _.get(item, "label.1"),
            lat: _.get(item, "gps.1.0"),
            lng: _.get(item, "gps.1.1"),
            ademe_id: _.get(item, "ademe_id.1"),
            label_range: _.get(item, "label_range.1"),
            label_z: _.get(item, "label_z.1"),
          })),
          (item) => `${item.lat}-${item.lng}`
        )
      );
      setResultAutocompleteTitle(resultSearchDpes?.resp_addresses_title || "");
    } catch (e: any) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
        duration: 2,
      });
    }
  };

  const handleSetDataModalFiabilite = (data, key) => {
    reliabilityRef.current = key;
    setDataModalFiabilite(data);
    setIsShowModalFiabilite(true);
  };

  const handleClickLabel = async (gpsItem, mapRef, sliderRef) => {
    const center = mapRef.current?.getCenter() || {};
    const resultSearchDpes = await mutateSearchDpesMap({
      ref_ademe: gpsItem.ademe_id,
      geometry: {
        coordinates: [center.lng, center.lat],
      },
      zoom: mapRef.current?.getZoom() || 18,
    });

    setSelectedLabelId(gpsItem.ademe_id);
    setResultAutocomplete(resultSearchDpes.resp_addresses);
    setResultAutocompleteTitle(resultSearchDpes.resp_addresses_title);
    setSearchDpesMap(
      _.uniqBy(
        resultSearchDpes.surrounding_addresses.map((item: any) => ({
          label: _.get(item, "label.1"),
          lat: _.get(item, "gps.1.0"),
          lng: _.get(item, "gps.1.1"),
          ademe_id: _.get(item, "ademe_id.1"),
          label_range: _.get(item, "label_range.1"),
          label_z: _.get(item, "label_z.1"),
        })),
        (item) => `${item.lat}-${item.lng}`
      )
    );

    sliderRef.current?.slickGoTo(0);
  };

  // const handleOkFiabilite = async () => {
  //   if (reliabilityRef.current) {
  //     try {
  //       await mutateChatInit({
  //         ref_ademe: id,
  //         from: "controls",
  //         from_details: {
  //           control_type: reliabilityRef.current,
  //         },
  //       });
  //       setIsShowModalFiabilite(false);
  //       handleToInsight();
  //     } catch (e) {
  //       console.log("error", e);
  //     }
  //   }
  // };

  const tabItems: TabsProps["items"] = useMemo(() => {
    const renderTabDuBien = () => {
      const TAB_DU_BIEN1 = [
        {
          icon: IconRuler,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.surface.0",
            ""
          )} :`,
          value: _.get(dataHomeUploaded, "project_details.surface.1", ""),
        },
        {
          icon: IconHouse,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.housing_type.0",
            ""
          )} :`,
          value: _.get(dataHomeUploaded, "project_details.housing_type.1", ""),
        },
        {
          icon: IconCalendar,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.construction_year.0",
            ""
          )} `,
          value: _.get(
            dataHomeUploaded,
            "project_details.construction_year.1",
            ""
          ),
        },
        {
          icon: IconStair,
          label: `${_.get(dataHomeUploaded, "project_details.floor.0", "")} : `,
          value: _.get(dataHomeUploaded, "project_details.floor.1", ""),
        },
      ];

      const TAB_DU_BIEN2 = [
        {
          icon: IconWall,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.materials.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.materials.1", ""),
        },
        {
          icon: IconWind,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.airflow.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.airflow.1", ""),
        },
        {
          icon: IconIce,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.aircon.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.aircon.1", ""),
        },
        {
          icon: IconEnergy,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.energy.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.energy.1", ""),
        },
      ];

      return (
        <div className="info-du-bien">
          <div className="info-du-bien__inner">
            <ul>
              {TAB_DU_BIEN1.map((item, index) => {
                const Icon = item.icon;
                return (
                  <li key={index}>
                    <Flex align="center">
                      <Icon className="mr-1" />
                      {item.label && (
                        <span className="text-medium mr-1">{item.label}</span>
                      )}
                      {item.value && (
                        <p className="text-medium-bold text-truncate">
                          {item.value}
                        </p>
                      )}
                    </Flex>
                  </li>
                );
              })}
            </ul>

            <ul>
              {TAB_DU_BIEN2.map((item, index) => {
                const Icon = item.icon;
                return (
                  <li key={index}>
                    <Flex align="start">
                      <div className="flex items-center flex-shrink-0">
                        <Icon className="mr-1 flex-shrink-0" />
                        {item.label && (
                          <p className="text-medium mr-1">{item.label} </p>
                        )}
                      </div>
                      {item.value && (
                        <p
                          className="text-medium-bold text-truncate max-w-[90%] relative top-[1px]"
                          style={{ lineHeight: "33px" }}
                        >
                          {item.value}
                        </p>
                      )}
                    </Flex>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mt-3">
            <div className="flex items-center gap-1">
              <IconTemperature />
              <span className="text-medium">
                {_.get(
                  dataHomeUploaded,
                  "project_details.heating_system.0",
                  ""
                )}{" "}
                :{" "}
                <b>
                  {_.get(
                    dataHomeUploaded,
                    "project_details.heating_system.1",
                    ""
                  )}
                </b>
              </span>
            </div>
            <p className="text-medium mt-1">
              {_.get(
                dataHomeUploaded,
                "project_details.heating_system_details.1",
                ""
              )}
            </p>
          </div>

          <div className="mt-2">
            <div className="flex items-center gap-1">
              <IconWater />
              <span className="text-medium">
                {_.get(dataHomeUploaded, "project_details.hot_water.0", "")} :{" "}
                <b>
                  {_.get(dataHomeUploaded, "project_details.hot_water.1", "")}
                </b>
              </span>
            </div>
            <p className="text-medium mt-1">
              {_.get(
                dataHomeUploaded,
                "project_details.hot_water_details.1",
                ""
              )}
            </p>
          </div>
        </div>
      );
    };

    const renderTabDPE = () => {
      const doneOn = _.get(dataHomeUploaded, "dpe_details.done_on.1", dayjs());
      const validUntil = _.get(
        dataHomeUploaded,
        "dpe_details.valid_until.1",
        dayjs()
      );

      return (
        <div className="manage-infos">
          <div className="flex items-center gap-1">
            <IconHash />
            <span className="text-[16px]">
              ADEME : <b>{_.get(dataHomeUploaded, "ademe", "")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconOldCalendar />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.done_on.0", "")} :{" "}
              <b>{dayjs.unix(doneOn).format("DD/MM/YYYY")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconOldCalendar />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.valid_until.0", "")} :{" "}
              <b>{dayjs.unix(validUntil).format("DD/MM/YYYY")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconCog className="w-8 h-8" />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.file_url.0", "")} :{" "}
              <b>{_.get(dataHomeUploaded, "dpe_details.file_url.1", "")}</b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconCog className="w-8 h-8" />
            <span className="text-[16px]">
              {_.get(dataHomeUploaded, "dpe_details.diagnotic_software.0", "")}{" "}
              :{" "}
              <b>
                {_.get(
                  dataHomeUploaded,
                  "dpe_details.diagnotic_software.1",
                  ""
                )}
              </b>
            </span>
          </div>

          <div className="flex items-center gap-1">
            <IconHouse className="w-8 h-8" />
            <span className="text-[16px]">
              {_.get(
                dataHomeUploaded,
                "dpe_details.diagnotic_calculation_version.0",
                ""
              )}{" "}
              :{" "}
              <b>
                {_.get(
                  dataHomeUploaded,
                  "dpe_details.diagnotic_calculation_version.1",
                  ""
                )}
              </b>
            </span>
          </div>

          {_.get(dataHomeUploaded, "dpe_details.prev_ref.1") && (
            <div className="flex items-center gap-1">
              <IconHash />
              <span className="text-[16px]">
                {_.get(dataHomeUploaded, "dpe_details.prev_ref.0", "")} :{" "}
                <b style={{ color: "rgb(22, 119, 255)" }}>
                  {_.get(dataHomeUploaded, "dpe_details.prev_ref.1", "")}
                </b>
              </span>
            </div>
          )}

          {_.get(dataHomeUploaded, "dpe_details.prev_reason.1") && (
            <div className="flex items-center gap-1">
              <IconHelp className="w-8 h-8" />
              <span className="text-[16px]">
                {_.get(dataHomeUploaded, "dpe_details.prev_reason.0", "")} :{" "}
                <b>
                  {_.get(dataHomeUploaded, "dpe_details.prev_reason.1", "")}
                </b>
              </span>
            </div>
          )}
        </div>
      );
    };

    return [
      { key: "1", label: "Infos du bien", children: renderTabDuBien() },
      { key: "2", label: "Infos du DPE", children: renderTabDPE() },
    ];
  }, [dataHomeUploaded]);

  const isShowOverlay = useMemo(() => {
    const userToken = getUserToken();
    const userInfoAWS = cognitoUserPool.getCurrentUser();
    const isLogin = !_.isEmpty(userInfoAWS) || !!userToken;

    return (
      !isLogin ||
      _.get(dataHomeUploaded, "payment_required") === true ||
      _.get(dataHomeUploaded, "quota_use") === 1
    );
  }, [dataHomeUploaded]);

  const renderResultUploaded = () => {
    if (isPendingUploaded || isLoading) {
      return (
        <div className="loading-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      );
    }

    if (isError && _.isEmpty(dataHomeUploaded)) {
      return (
        <Card className="mb-3 min-h-[400px] flex items-center justify-center">
          <Empty />
        </Card>
      );
    }

    if (isSuccess && !_.isEmpty(dataHomeUploaded)) {
      const userToken = getUserToken();
      const userInfoAWS = cognitoUserPool.getCurrentUser();
      const isLogin = !_.isEmpty(userInfoAWS) || !!userToken;
      return (
        <div className={classNames("energy-home__result")}>
          <Card className="energy-home__project-card mb-3">
            <div className="energy-home__project">
              <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
                <IconMondpe className="w-7 h-7 flex-shrink-0" />
                {_.get(dataHomeUploaded, "project_name")}
              </h3>

              <Flex align="center" className="mb-2">
                <IconLocation />
                <span className="text-medium-bold ml-1">
                  {_.get(dataHomeUploaded, "project_details.address.1", "")}
                </span>
              </Flex>

              <div className="energy-home__project-info">
                <Tabs
                  centered
                  activeKey={tab}
                  items={tabItems}
                  onChange={setTab}
                />

                <ResultatTab dataDynamicInfo={dataDynamicInfo} />
              </div>
            </div>
          </Card>

          {isShowOverlay && (
            <MonDPE
              resultAutocomplete={resultAutocomplete}
              dataDynamicInfo={dataDynamicInfo}
              isPendingSearchMap={isPendingSearchMap}
              dataHomeUploaded={dataHomeUploaded}
              isPendingUploaded={isPendingUploaded}
              svgGraphePrice={svgGraphePrice}
              searchDpesMap={searchDpesMap}
              resultAutocompleteTitle={resultAutocompleteTitle}
              dataFiabilite={dataFiabilite}
              isFetchingFiabilite={isFetchingFiabilite}
              selectedLabelId={selectedLabelId}
              onClickLabel={handleClickLabel}
            />
          )}

          <div className="energy-home__result-auth">
            <Fiabilite
              dataFiabilite={dataFiabilite}
              svgFiabilite={svgFiabilite}
              dataDynamicInfo={dataDynamicInfo}
              onSetDataModalFiabilite={handleSetDataModalFiabilite}
            />

            {!isShowOverlay && (
              <MonDPE
                resultAutocomplete={resultAutocomplete}
                dataDynamicInfo={dataDynamicInfo}
                isPendingSearchMap={isPendingSearchMap}
                dataHomeUploaded={dataHomeUploaded}
                isPendingUploaded={isPendingUploaded}
                svgGraphePrice={svgGraphePrice}
                searchDpesMap={searchDpesMap}
                resultAutocompleteTitle={resultAutocompleteTitle}
                dataFiabilite={dataFiabilite}
                isFetchingFiabilite={isFetchingFiabilite}
                selectedLabelId={selectedLabelId}
                onClickLabel={handleClickLabel}
              />
            )}

            {!_.isEmpty(dataSuggestion) && (
              <SuggestionRenovation
                dataSuggestion={dataSuggestion}
                dataHomeUploaded={dataHomeUploaded}
                openChainLit={handleToInsight}
                id={id}
              />
            )}

            <Package dataHomeUploaded={dataHomeUploaded} isLogin={isLogin} />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="energy-home energy-home--result">
      {renderResultUploaded()}
      {contextHolder}

      <Modal
        open={isShowModalFiabilite}
        centered
        title={_.get(dataModalFiabilite, "details.title", "")}
        onCancel={() => setIsShowModalFiabilite(false)}
        onOk={() => setIsShowModalFiabilite(false)}
        confirmLoading={isPendingChatInit}
        cancelButtonProps={{ className: "hidden" }}
        wrapClassName="!z-[9999]"
      >
        <div className="p-4">
          <ul style={{ listStyleType: "disc" }} className="flex flex-col gap-2">
            {_.get(dataModalFiabilite, "details.body", []).map(
              (item, index) => (
                <li key={index}>{item}</li>
              )
            )}
          </ul>
        </div>
      </Modal>

      <ModalDynamicInformation dataDynamicInfo={dataDynamicInfo} />
    </div>
  );
}

export default Dashboard;
