import React from 'react';
import { Package } from 'lucide-react';
import { Offer } from 'types/Stripe.type';
import 'styles/stripe.scss';
import { Steps } from 'components/steps/steps';

const OrderSummary: React.FC<Offer> = ({ name, price, description, refAdeme, address }) => {
    return (
        <>
            <Steps stepNames={["offer",'payment']} />
            <div className="max-w-md mx-auto">
                <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
                    <div className="flex items-center mb-4">
                        <Package className="w-6 h-6 package-icon mr-2" />
                        <h2 className="text-xl font-bold text-gray-800">Résumé de la commande</h2>
                    </div>

                    <div className="space-y-3">
                        <div className="flex justify-between text-gray-700">
                            <span>{name}</span>
                            <span>{price.toFixed(2)} €</span>
                        </div>
                        <span className="whitespace-pre-line">Référence ADEME : </span>
                        {refAdeme}
                        <br />
                        <span className="whitespace-pre-line">Adresse : </span>
                            {address}

                        <div className="flex justify-between text-gray-700">
                            <span className="whitespace-pre-line">
                                {description}
                            </span>
                        </div>

                        <div className="border-t pt-3 mt-3">
                            <div className="flex justify-between font-bold text-lg">
                                <span>Total</span>
                                <span>{price.toFixed(2)} €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderSummary;