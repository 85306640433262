import _ from "lodash";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Empty, Skeleton } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";

import { ArrowRightOutlined } from "@ant-design/icons";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { MAP_COLOR_DPE } from "constants/index";
import { getDashboardURL } from "routes/constants";
import useCommonStore from "stores/useCommon";

import "styles/address.scss";

function AddressPage() {
  const { address } = useParams();
  const navigate = useNavigate();
  const mapRef = useRef<any>();

  const { setIsLoadedPage } = useCommonStore();

  const [gps, setGps] = useState<any[]>([]);
  const [resultAutocomplete, setResultAutocomplete] = useState<any[]>([]);
  const [center, setCenter] = useState<any[]>([]);
  const [selectedLabelId, setSelectedLabelId] = useState("");
  const [allowShow, setAllowShow] = useState(false);

  const { data: dataAutoComplete } = useQuery({
    queryKey: [QUERY_KEYS.AUTOCOMPLETE_SEARCH, address],
    queryFn: () => API.mock.autoCompleteSearch(String(address)),
    enabled: !!address,
  });

  const {
    mutateAsync: mutateGetResultAutocomplete,
    isPending: isPendingGetResultAutocomplete,
    isSuccess,
  } = useMutation({
    mutationFn: (payload: any) => API.mock.getResultAutocomplete(payload),
  });

  useEffect(() => {
    const handleFetchData = async () => {
      if (!_.isEmpty(dataAutoComplete?.features)) {
        const payload = dataAutoComplete.features[0];

        const result = await mutateGetResultAutocomplete(payload);
        setResultAutocomplete(result.resp_addresses);
        setCenter(result.center || []);
        setGps(
          _.uniqBy(
            result.surrounding_addresses.map((item: any) => ({
              label: _.get(item, "label.1"),
              lat: _.get(item, "gps.1.0"),
              lng: _.get(item, "gps.1.1"),
              ademe_id: _.get(item, "ademe_id.1"),
              label_range: _.get(item, "label_range.1"),
              label_z: _.get(item, "label_z.1"),
            })),
            (item) => `${item.lat}-${item.lng}`
          )
        );
      }
    };

    handleFetchData();
  }, [address, dataAutoComplete, mutateGetResultAutocomplete]);

  useEffect(() => {
    if (isPendingGetResultAutocomplete) {
      setIsLoadedPage(true);
    }
  }, [isPendingGetResultAutocomplete, setIsLoadedPage]);

  useEffect(() => {
    if (!_.isEmpty(resultAutocomplete)) {
      setAllowShow(true);
    }
  }, [resultAutocomplete, setAllowShow]);

  const handleNavigateToDashboard = (item: any) => {
    navigate(getDashboardURL(_.get(item, "ademe_id.1")));
  };

  const handleClickLabel = async (gpsItem: any) => {
    const center = mapRef.current?.getCenter() || {};
    const result = await mutateGetResultAutocomplete({
      ref_ademe: gpsItem.ademe_id,
      geometry: {
        coordinates: [center.lng, center.lat],
      },
      zoom: mapRef.current?.getZoom() || 18,
    });

    setSelectedLabelId(gpsItem.ademe_id);
    setResultAutocomplete(result.resp_addresses);
    setCenter(result.center || []);
    setGps(
      _.uniqBy(
        result.surrounding_addresses.map((item: any) => ({
          label: _.get(item, "label.1"),
          lat: _.get(item, "gps.1.0"),
          lng: _.get(item, "gps.1.1"),
          ademe_id: _.get(item, "ademe_id.1"),
          label_range: _.get(item, "label_range.1"),
          label_z: _.get(item, "label_z.1"),
        })),
        (item) => `${item.lat}-${item.lng}`
      )
    );
  };

  const renderGPSItem = () => {
    if ((isPendingGetResultAutocomplete || !allowShow) && !isSuccess) {
      return (
        <div className="autocomplete-box">
          <div className="flex flex-wrap items-center justify-evenly gap-[30px]">
            {[...new Array(4)].map((item, index) => (
              <Skeleton.Node active key={index} />
            ))}
          </div>
        </div>
      );
    }

    if (!_.isEmpty(resultAutocomplete) && allowShow) {
      return (
        <div className="autocomplete-box">
          <div className="flex flex-wrap items-center justify-evenly gap-[30px]">
            {resultAutocomplete.map((item, index) => (
              <div className="autocomplete-item" key={index}>
                <div className="autocomplete-item__title">
                  <p
                    style={{
                      background: MAP_COLOR_DPE[_.get(item, "label.1")],
                    }}
                    className="min-w-[32px] rounded-full text-center"
                  >
                    {_.get(item, "label.1")}
                  </p>
                  <p>{_.get(item, "ademe_id.1")}</p>
                </div>
                <div className="autocomplete-item__content">
                  <ul
                    style={{ listStyleType: "disc" }}
                    className="flex flex-col gap-2 pl-8"
                  >
                    {_.get(item, "date_dpe.1") && (
                      <li>
                        <p className="truncate">
                          {_.get(item, "date_dpe.0")}:{" "}
                          {_.get(item, "date_dpe.1")}
                        </p>
                      </li>
                    )}

                    {_.get(item, "address.1") && (
                      <li>
                        <p className="truncate">{_.get(item, "address.1")}</p>
                      </li>
                    )}

                    {_.get(item, "floor.1") && (
                      <li>
                        <p className="truncate">
                          {_.get(item, "floor.0")}: {_.get(item, "floor.1")}
                        </p>
                      </li>
                    )}

                    {_.get(item, "housing_surface.1") && (
                      <li>
                        <p className="truncate">
                          {_.get(item, "housing_surface.0")}:{" "}
                          {_.get(item, "housing_surface.1")}
                        </p>
                      </li>
                    )}

                    {_.get(item, "housing_add_compl.1") && (
                      <li>
                        <p className="truncate">
                          {_.get(item, "housing_add_compl.1")}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
                <div
                  className="flex items-center cursor-pointer justify-end mt-6 gap-3"
                  onClick={() => handleNavigateToDashboard(item)}
                >
                  <span>Selectionner</span>
                  <ArrowRightOutlined />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <Empty className="min-h-[400px] flex flex-col items-center justify-center" />
    );
  };

  return (
    <div className="energy-address">
      {!_.isEmpty(gps) && isSuccess && (
        <div className="map-wrapper mb-8">
          <MapContainer
            center={{
              lat: center[1] || gps[0]?.lat,
              lng: center[0] || gps[0]?.lng,
            }}
            zoom={18}
            scrollWheelZoom={false}
            ref={mapRef}
          >
            <TileLayer
              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/attributions">CartoDB</a>'
            />
            {gps.map((item, index) => {
              const customIcon = L.divIcon({
                className: "custom-div-icon",
                html: ReactDOMServer.renderToString(
                  <div
                    data-id={item.ademe_id}
                    className={classNames(
                      "label-container min-w-[32px] min-h-[25px] rounded-3xl flex items-center justify-center",
                      {
                        "label-container--selected":
                          item.ademe_id === selectedLabelId,
                      }
                    )}
                    style={{
                      background: MAP_COLOR_DPE[item.label],
                      zIndex: 5,
                    }}
                  >
                    <span className="label-text font-medium">
                      {item.label}
                      {item.label_range?.length > 1 ? "+" : ""}
                    </span>
                  </div>
                ),
                iconSize: [30, 30],
              });

              return (
                <Marker
                  position={item}
                  key={index}
                  icon={customIcon}
                  zIndexOffset={item.label_z}
                  eventHandlers={{
                    click: () => handleClickLabel(item),
                  }}
                />
              );
            })}

            <Marker
              position={[center[1] || gps[0]?.lat, center[0] || gps[0]?.lng]}
              zIndexOffset={99999}
            >
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      )}

      {(isPendingGetResultAutocomplete || !allowShow) && !isSuccess && (
        <Skeleton.Node active className="full mb-8" />
      )}

      {(allowShow || (!_.isEmpty(gps) && isSuccess)) && (
        <div className="info-box">
          <p>Sélectionnez le DPE que vous souhaitez analyser.</p>

          <p>
            S’il n’apparaît pas, le DPE n’a peut être pas encore été réalisé ou
            déposé
          </p>

          <p>
            sur la base de l'ADEME, vous pouvez le demander à votre vendeur.
          </p>

          <p>
            Vous pouvez nous contacter pour plus d’informations :{" "}
            <a href="mailto:contact@checkDPE.fr">contact@checkDPE.fr</a>
          </p>
        </div>
      )}

      {renderGPSItem()}
    </div>
  );
}

export default AddressPage;
