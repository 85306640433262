import { useEffect, useState } from 'react';
import { cognitoUserPool } from 'config';
import 'styles/account.scss';
import { clearUserInfo, clearUserToken, getConfirmationEmail } from 'services/storages/userStorage';
import { personaeOptions} from 'constants/account';
import { useGetAccountQuery } from 'queries/useGetAccountQuery';
import { useAccountMutation } from 'mutations/useAccountMutation';
import { AccountData } from 'types/AccountData.type';
import { useNavigate } from "react-router-dom";
import PAGES from 'routes/constants';

function AccountPage() {
    const navigate = useNavigate();
    const [accountData, setAccountData] = useState<AccountData>();
    const userInfoAWS = cognitoUserPool.getCurrentUser();
    const confirmationEmail = getConfirmationEmail();
    const email = userInfoAWS?.getUsername() || confirmationEmail;

    if (!userInfoAWS) {
        navigate(PAGES.login)
    }

    const { data: account, isSuccess: getAccountSuccess, error: fetchAccountError } = useGetAccountQuery(email)
    const { mutate } = useAccountMutation()

    useEffect(() => {
        setAccountData(account)
    }, [account, getAccountSuccess, fetchAccountError]);

    const handleUpdate = async () => {
        if (!email || !accountData) return;
        mutate(accountData)
    };

    const handleDisconnect = async () => {
        clearUserInfo();
        clearUserToken();
        userInfoAWS?.signOut();
        navigate(PAGES.home)
    };

    return (
        <div className="energy-account">
            {accountData && (
                <div className="energy-account__content">
                    <div className="profile-options">
                        <h2>Votre profil</h2>
                        <form id="account-form">
                            <div className="input-stack">
                                <input
                                    type="text"
                                    value={accountData.first_name}
                                    onChange={(e) => setAccountData({ ...accountData, first_name: e.target.value })}
                                    placeholder="Prénom"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    value={accountData.last_name}
                                    onChange={(e) => setAccountData({ ...accountData, last_name: e.target.value })}
                                    placeholder="Nom"
                                    className="form-input"
                                />
                            </div>
                        </form>
                        
                        {personaeOptions.map((option) => (
                            <div key={option.id} className="option-item">
                                <label>
                                    <input
                                        type="radio"
                                        name="personae"
                                        value={option.id}
                                        checked={accountData.personae === option.id}
                                        onChange={(e) => setAccountData({ ...accountData, personae: Number(e.target.value) })}
                                    />
                                    <div>
                                        <div className="option-title">{option.label}</div>
                                        <div className="option-description">{option.description}</div>
                                    </div>
                                </label>
                            </div>
                        ))}

                        <button 
                            onClick={handleUpdate}
                            className="btn-update"
                        >
                            Mettre à jour
                        </button>
                        <button
                            onClick={handleDisconnect}
                            className="btn-update btn-disconnect"
                        >
                            Se déconnecter
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AccountPage;