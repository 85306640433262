const KEY_LAST_ACCESS = "@energy-last_access_page";

export const setLastAccessPage = (page: string) => {
  sessionStorage.setItem(KEY_LAST_ACCESS, page);
};

export const getLastAccessPage = () => sessionStorage.getItem(KEY_LAST_ACCESS);

export const clearLastAccessPage = () => {
  sessionStorage.removeItem(KEY_LAST_ACCESS);
};
