import { ReactComponent as IconExternal } from "assets/images/external.svg";
import { ReactComponent as IconComposite } from "assets/images/composite.svg";
import { ReactComponent as IconBrick } from "assets/images/brick.svg";
import { ReactComponent as IconMap } from "assets/images/map.svg";
import { ReactComponent as IconBuilding } from "assets/images/building.svg";
import { ReactComponent as IconCalendar } from "assets/images/calendar.svg";
import { ReactComponent as IconMountain } from "assets/images/mountain.svg";
import { ReactComponent as IconStaircase } from "assets/images/staircase.svg";
import { ReactComponent as IconGlazingWindow } from "assets/images/glazing-window.svg";
import { ReactComponent as IconHeating } from "assets/images/heating.svg";
import { ReactComponent as IconWind } from "assets/images/wind.svg";
import { ReactComponent as IconFloorLow } from "assets/images/floor_low.svg";
import { ReactComponent as IconFloorHigh } from "assets/images/floor_high.svg";
import { ReactComponent as IconWater } from "assets/images/water.svg";
import { ReactComponent as IconThermalBridge } from "assets/images/thermal_bridge.svg";

import { ReactComponent as IconRoof } from "assets/images/renovation/house.svg";
import { ReactComponent as IconWallNew } from "assets/images/renovation/wall.svg";

export const MapIconField: any = {
  type_local_adjacent: IconExternal,
  materiau: IconComposite,
  exposition: IconBrick,
  surface: IconBrick,
  epaisseur: IconBrick,
  isolation: IconGlazingWindow,
  dept: IconMap,
  alt: IconMountain,
  year_cons: IconCalendar,
  livable_surface_housing: IconBrick,
  livable_surface_building: IconBrick,
  nb_floors: IconStaircase,
  avg_height_floor: IconBrick,
  type_bien: IconBuilding
};

export const MAP_ICON_FIABILITE: Record<string, any> = {
  wall: IconWallNew,
  roof: IconRoof,
  window: IconGlazingWindow,
  heating: IconHeating,
  airflow: IconWind,
  floor_low: IconFloorLow,
  floor_high: IconFloorHigh,
  admin: IconCalendar,
  hot_water: IconWater,
  thermal_bridge: IconThermalBridge,
};
