import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormProps,
  message,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  InboxOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { getDashboardURL } from "routes/constants";
import useCommonStore from "stores/useCommon";

import "styles/upload.scss";

function UploadPage() {
  const [formUpload] = Form.useForm();
  const navigate = useNavigate();

  const { setIsLoadedPage } = useCommonStore();
  const [messageApi, contextHolder] = message.useMessage();

  const [fileName, setFileName] = useState("");

  const { mutateAsync: mutateUploadFile, isPending } = useMutation({
    mutationFn: ({ path, data }: { path: string; data: any }) =>
      API.mock.uploadFile(path, data),
  });

  const { data: dataBeforeUpload, isFetching: isFetchingDataUpload } = useQuery(
    {
      queryKey: [QUERY_KEYS.GET_DATA_UPLOAD, fileName],
      queryFn: () => API.mock.getDataUpload(fileName),
      enabled: !!fileName,
    }
  );

  const {
    mutateAsync: mutateGetRefAdemeFromDataUpload,
    isPending: isPendingGetRefAdemeFromDataUpload,
  } = useMutation({
    mutationFn: (payload: any) => API.mock.getRefAdemeFromDataUpload(payload),
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoadedPage(true);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload: FormProps["onFinish"] = async (values: any) => {
    // console.log("onFinish:", values.file[0]);
    try {
      const formData = new FormData();
      formData.append("key", dataBeforeUpload.fields.key);
      formData.append("AWSAccessKeyId", dataBeforeUpload.fields.AWSAccessKeyId);
      formData.append(
        "x-amz-security-token",
        dataBeforeUpload.fields["x-amz-security-token"]
      );
      formData.append("policy", dataBeforeUpload.fields.policy);
      formData.append("signature", dataBeforeUpload.fields.signature);
      formData.append("file", values.file[0].originFileObj);

      await mutateUploadFile({
        path: dataBeforeUpload.url,
        data: formData,
      });

      const dataRefAdeme = await mutateGetRefAdemeFromDataUpload(
        dataBeforeUpload?.metadata?.id
      );
      formUpload.resetFields();
      setFileName("");
      messageApi.open({
        type: "success",
        content: "Chargment OK",
      });

      if (!_.isEmpty(dataRefAdeme)) {
        navigate(getDashboardURL(dataRefAdeme?.ref_ademe));
      }
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_MOCK_DATA] });
    } catch (e: any) {
      console.log(e.response);
      messageApi.open({
        type: "error",
        content: "Chargment Échec",
      });
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const props: UploadProps = {
    accept: "*",
    name: "file",
    multiple: false,
    onChange({ file }) {
      setFileName(file.name);
    },
    beforeUpload: () => {
      return false;
    },
    showUploadList: true,
    maxCount: 1,
    disabled: isFetchingDataUpload,
  };

  return (
    <div className="energy-upload">
      <h1 className="text-center mb-8">Upload du DPE</h1>
      <p className="max-w-[400px] text-center mx-auto mb-6 text-[16px]">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus
        fugit harum blanditiis in magnam odit quaerat. Quibusdam laudantium
        eligendi delectus, odio sequi deleniti necessitatibus, dolor mollitia
        officiis esse et aperiam!
      </p>
      <Form
        form={formUpload}
        name="upload-form"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          file: null,
          votre: "",
        }}
        onFinish={handleUpload}
      >
        <div
          className="flex items-start gap-8 inner-form"
          data-testid="upload-drag"
        >
          <Form.Item
            name="file"
            // label="Upload your DPE"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="w-full m-0"
          >
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                {isFetchingDataUpload ? (
                  <Spin indicator={<LoadingOutlined />} size="large" />
                ) : (
                  <InboxOutlined />
                )}
              </p>
              <p className="ant-upload-text">
                Déposez votre DPE (fichier pdf) ici pour l'uploader
              </p>
            </Upload.Dragger>
          </Form.Item>
        </div>
        {dataBeforeUpload && (
          <Button
            htmlType="submit"
            type="primary"
            loading={isPending || isPendingGetRefAdemeFromDataUpload}
            className="mt-4"
            size="large"
            disabled={!fileName || _.isEmpty(dataBeforeUpload)}
          >
            Upload
          </Button>
        )}
      </Form>

      <div className="flex items-center justify-center gap-2 detail-additional">
        <span className="font-bold text-[18px]">Détail additionnel</span>
        <InfoCircleOutlined />
      </div>
      {contextHolder}
    </div>
  );
}

export default UploadPage;
