import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useInsight = create<{
  buttonId: string;
  setButtonId: (redirectUrl: string) => void;
  reset: () => void;
}>()(
  persist(
    (set) => ({
      buttonId: "",
      setButtonId: (buttonId: string) => set(() => ({ buttonId })),
      reset: () => set(() => ({ buttonId: "" })),
    }),
    {
      name: "@energy-insight",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useInsight;
