import _ from "lodash";
import { useMutation } from "@tanstack/react-query";
import { FC } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import PAGES from "routes/constants";
import Logo from "assets/images/logo.png";
import API from "services/api";
import { setLastAccessPage } from "services/storages/expiredStorage";
import usePackage from "stores/usePackage";

import "styles/dashboard.scss";

interface Props {
  dataHomeUploaded: any;
  isLogin: boolean;
}

const Package: FC<Props> = ({ dataHomeUploaded, isLogin }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setRedirectUrlAfterAuth } = usePackage();

  const { mutateAsync: mutateACKOverlayPackage } = useMutation({
    mutationFn: (params: any) => API.mock.ackOverlayPackage(params),
  });

  const handleRapport = async () => {
    if (_.get(dataHomeUploaded, "quota_token", "")) {
      await mutateACKOverlayPackage({
        ref_ademe: id,
        quota_token: _.get(dataHomeUploaded, "quota_token", ""),
      });

      if (id) {
        const dashboardUrl = PAGES.dashboard.replace(":id", id || "");
        navigate(dashboardUrl, {
          state: { refresh: true },
          replace: true,
        });
      }
    }
  };

  const handlePaymentPackage = (offerId: string) => {
    setRedirectUrlAfterAuth(window.location.hash?.slice(1));
    navigate(PAGES.stripe, {
      state: {
        offerId: offerId,
        refAdeme: dataHomeUploaded.ademe,
        address: dataHomeUploaded.project_details.address,
      },
    });
  };

  if (!isLogin || _.get(dataHomeUploaded, "payment_required") === true) {
    return (
      <div className="overlay">
        <div className="flex flex-col gap-4 max-w-max mx-auto">
          <div className="overlay__content">
            <div className="offre-item">
              <div className="flex-grow">
                <p className="title text-center text-[#1e6862]">Fiabilité</p>
                <p className="text-[18px] text-center line-through mb-2">
                  <span className="text-[24px] font-semibold">30 €</span> /
                  analyse
                </p>
                <p className="text-[18px] text-center mb-1">
                  <strong>Offre lancement : 15 €</strong>
                </p>
                <p className="text-[16px] text-center">jusqu’au 30/04/2025</p>

                <ul className="flex flex-col gap-3 my-4">
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Score de fiabilité de mon DPE
                    </span>
                  </li>
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Identification des anomalies éventuelles
                    </span>
                  </li>
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Comparaison de mon DPE aux logements similaires
                    </span>
                  </li>
                </ul>

                <Button
                  type="primary"
                  className="btn-rapport"
                  onClick={() => handlePaymentPackage("1")}
                >
                  Obtenir mon rapport
                </Button>

                <p className="font-semibold text-[18px] mt-4">
                  Cette offre vous permet de mesurer le niveau de fiabilité de
                  votre DPE et de comprendre les éventuels problèmes de saisies
                  ou de cohérence.
                </p>
                <p className="font-semibold text-[18px] mt-4">
                  Pour en savoir plus sur le rapport de l’offre Fiabilité :
                  Cliquez ici
                </p>
              </div>

              <img src={Logo} alt="logo" className="mx-auto mt-5 w-[80px]" />
            </div>

            <div className="offre-item offre-item--dark">
              <div className="flex-grow">
                <p className="title text-center text-[#002060ff]">
                  Fiabilité + travaux
                </p>
                <p className="text-[18px] text-center">
                  <span className="text-[24px] font-semibold">95 €</span> /
                  analyse
                </p>

                <ul className="flex flex-col gap-3 my-4">
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Score de fiabilité de mon DPE
                    </span>
                  </li>
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Identification des anomalies éventuelles
                    </span>
                  </li>
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Comparaison de mon DPE aux logements similaires
                    </span>
                  </li>

                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Proposition de travaux adaptée à mon projet
                    </span>
                  </li>
                </ul>
                <Button
                  type="primary"
                  className="btn-rapport btn-rapport--dark"
                  onClick={() => handlePaymentPackage("2")}
                >
                  Obtenir mon rapport+
                </Button>

                <p className="font-semibold text-[16px] mt-4 text-justify">
                  Cette offre vous permet de mesurer le niveau de fiabilité de
                  votre DPE et de comprendre les éventuels problèmes de saisies
                  ou de cohérence, puis de vous projeter dans des travaux
                  permettant l’amélioration de votre logement selon votre projet
                  (délai, budget, …).
                </p>
                <p className="font-semibold text-[18px] mt-4">
                  Pour en savoir plus sur le rapport de l’offre Fiabilité +
                  travaux : Cliquez ici
                </p>
              </div>
              <img src={Logo} alt="logo" className="mx-auto mt-5 w-[80px]" />
            </div>
          </div>

          <div className="offre-login">
            <span className="text-[20px] font-semibold text-center">
              J’ai déjà un compte checkDPE
            </span>
            <Button
              type="primary"
              className="btn-rapport"
              onClick={() => {
                setLastAccessPage(window.location.hash?.slice(1));
                navigate(PAGES.login);
              }}
            >
              Connexion
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (_.get(dataHomeUploaded, "quota_use") === 1) {
    return (
      <div className="overlay">
        <div className="flex flex-col gap-4 max-w-max mx-auto">
          <div className="overlay__content">
            <div className="offre-item">
              <div className="flex-grow">
                <p className="title text-center text-[#1e6862]">Fiabilité</p>
                <p className="text-[18px] text-center mb-2">
                  <span className="text-[24px] font-semibold">
                    {_.get(dataHomeUploaded, "quota_use")} jeton
                  </span>{" "}
                  / analyse
                </p>
                <p className="text-[18px] text-center mb-1">
                  {_.get(dataHomeUploaded, "quota_info", "")}
                </p>

                <ul className="flex flex-col gap-3 my-4">
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Score de fiabilité de mon DPE
                    </span>
                  </li>
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Identification des anomalies éventuelles
                    </span>
                  </li>
                  <li className="flex items-baseline gap-4">
                    <CheckOutlined className="text-[18px] relative top-[2px]" />
                    <span className="text-[18px] font-bold">
                      Comparaison de mon DPE aux logements similaires
                    </span>
                  </li>
                </ul>

                <Button
                  type="primary"
                  className="btn-rapport"
                  onClick={handleRapport}
                >
                  Obtenir mon rapport
                </Button>

                <p className="font-semibold text-[18px] mt-4">
                  Cette offre vous permet de mesurer le niveau de fiabilité de
                  votre DPE et de comprendre les éventuels problèmes de saisies
                  ou de cohérence.
                </p>
                <p className="font-semibold text-[18px] mt-4">
                  Pour en savoir plus sur le rapport de l’offre Fiabilité :
                  Cliquez ici
                </p>
              </div>

              <img src={Logo} alt="logo" className="mx-auto mt-5 w-[80px]" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Package;
