import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from 'components/stripe/payment-form';
import CompletePayment from 'components/stripe/payment-complete';
import OrderSummary from 'components/stripe/order-summary';
import { offers, appearance, loader, stripePromise, PAYMENT_RETRIEVE_ERROR} from 'constants/stripe';
import { useLocation } from 'react-router-dom';
import { useMutationPaymentIntent } from 'mutations/useMutationPaymentIntent'
import { Offer } from 'types/Stripe.type';
import PAGES from 'routes/constants';
import "styles/stripe.scss";
import { getAdemeNumber, setAdemeNumber } from 'services/storages/userStorage';

function StripePaymentPage() {
    const [clientSecret, setClientSecret] = useState("");
    const location = useLocation();
    const isPaymentComplete = location.pathname.includes(PAGES.paymentComplete);
    const orderData: Offer | undefined = !isPaymentComplete ? offers[location.state?.offerId] : undefined;
    if (orderData) {
        orderData.refAdeme = location.state?.refAdeme;
        orderData.address = location.state?.address;
    }
    if (!getAdemeNumber()) {
        setAdemeNumber(location.state?.refAdeme)
    }
    const { data: stripeData, mutate, isSuccess: stripeSuccess, error: stripeError } = useMutationPaymentIntent()
    const [snackbar, setSnackbar] = useState({
        show: false,
        message: '',
        type: 'success'
    });
    const showSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbar({ show: true, message, type });
        setTimeout(() => {
            setSnackbar(prev => ({ ...prev, show: false }));
        }, 3000);
    };


    useEffect(() => {
        if (!isPaymentComplete && orderData) {
            mutate(orderData)
        }
    }, [isPaymentComplete, orderData, mutate]);

    useEffect(() => {
        if (stripeSuccess && stripeData?.client_secret) {
            setClientSecret(stripeData.client_secret);
        }
        if (stripeError) {
            showSnackbar(PAYMENT_RETRIEVE_ERROR, "error")
        }
    }, [snackbar,stripeData, stripeSuccess, stripeError]);


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const urlClientSecret = searchParams.get('payment_intent_client_secret');
        setClientSecret(urlClientSecret || "");
    }, [isPaymentComplete]);
    
    return (
        <div className="min-h-screen bg-gray-100 py-12">
            <div className="max-w-md mx-auto">
                <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
                    {isPaymentComplete ? '' : 'Finaliser la commande'}
                </h1>

                {!isPaymentComplete && orderData && (
                    <OrderSummary 
                        name={orderData.name} 
                        price={orderData.price} 
                        description={orderData.description}
                        refAdeme = {orderData.refAdeme}
                        address = {orderData.address}
                    />
                )}
                
                {clientSecret && (
                    <Elements options={{ clientSecret, appearance, loader }} stripe={stripePromise}>
                        {isPaymentComplete ? 
                            <CompletePayment clientSecret={clientSecret} /> : 
                            <PaymentForm amount={orderData?.price} />
                        }
                    </Elements>
                )}
            </div>
        </div>
    );
}

export default StripePaymentPage;