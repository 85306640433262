import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const usePackage = create<{
  redirectUrl: string;
  isAutoOpenInsight: boolean;
  redirectUrlAfterAuth: string;
  setRedirectUrl: (redirectUrl: string) => void;
  setRedirectUrlAfterAuth: (redirectUrlAfterAuth: string) => void;
  setIsAutoOpenInsight: (isAutoOpenInsight: boolean) => void;
  reset: () => void;
}>()(
  persist(
    (set) => ({
      redirectUrl: "",
      isAutoOpenInsight: false,
      redirectUrlAfterAuth: "",
      setRedirectUrl: (redirectUrl: string) => set(() => ({ redirectUrl })),
      setRedirectUrlAfterAuth: (redirectUrlAfterAuth: string) =>
        set(() => ({ redirectUrlAfterAuth })),

      setIsAutoOpenInsight: (isAutoOpenInsight: boolean) =>
        set(() => ({ isAutoOpenInsight })),
      reset: () => set(() => ({ redirectUrl: "" })),
    }),
    {
      name: "@energy-package",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default usePackage;
