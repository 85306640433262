import { create } from "zustand";

export interface CommonType {
  isShowInsight: boolean;
  setIsShowInsight: (isShowInsight: boolean) => void;

  isLoadedPage: boolean;
  setIsLoadedPage: (isLoadedPage: boolean) => void;

  dynamicInfoKey: string;
  setDynamicInfoKey: (dynamicInfoKey: string) => void;
  isShowModalDynamicInfo: boolean;
  setIsShowModalDynamicInfo: (isShowModalDynamicInfo: boolean) => void;

  alertTop: any;
  setAlertTop: (alertTop: any) => void;
  closeAlertTop: () => void;
}

const useCommonStore = create<CommonType>((set) => ({
  isShowInsight: false,
  setIsShowInsight: (isShowInsight: boolean) => set(() => ({ isShowInsight })),

  isLoadedPage: false,
  setIsLoadedPage: (isLoadedPage: boolean) => set(() => ({ isLoadedPage })),

  dynamicInfoKey: "",
  setDynamicInfoKey: (dynamicInfoKey: string) =>
    set(() => ({ dynamicInfoKey })),
  isShowModalDynamicInfo: false,
  setIsShowModalDynamicInfo: (isShowModalDynamicInfo: boolean) =>
    set(() => ({ isShowModalDynamicInfo })),

  alertTop: null,
  setAlertTop: (alertTop: any) => set(() => ({ alertTop })),
  closeAlertTop: () => set(() => ({ alertTop: null })),
}));

export default useCommonStore;
