import { FC, useEffect, useState } from "react";
import { Empty, message, Skeleton, Tabs, TabsProps } from "antd";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import useCommonStore from "stores/useCommon";

import API from "services/api";
import _ from "lodash";

const KEY_TAB = {
  DPE: "dpe",
  CO2: "co2",
};

const ResultatTab: FC = ({ dataDynamicInfo }: any) => {
  const { id } = useParams();
  const [messageApi] = message.useMessage();
  const [ResultatDPE, setResultatDPE] = useState(null);
  const [ResultatCO2, setResultatCO2] = useState(null);
  const [isLoadingDPE, setIsLoadingDPE] = useState(true);
  const [isLoadingCO2, setIsLoadingCO2] = useState(true);

  const { setDynamicInfoKey, setIsShowModalDynamicInfo } = useCommonStore();

  const [tab, setTab] = useState(KEY_TAB.DPE);

  const { mutateAsync: mutateDPE, isSuccess: isSuccessDPE } = useMutation({
    mutationFn: (params: any) => API.mock.getResultat(params),
    retry: 0,
  });

  const { mutateAsync: mutateCO2, isSuccess: isSuccessCO2 } = useMutation({
    mutationFn: (params: any) => API.mock.getResultat(params),
    retry: 0,
  });

  useEffect(() => {
    loadData(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (value: string) => {
    setTab(value);

    if (value === KEY_TAB.CO2 && !ResultatCO2) {
      loadData(value);
    }
  };

  const handleViewInfo = (key: string) => {
    setDynamicInfoKey(key);
    setIsShowModalDynamicInfo(true);
  };

  const loadData = async (currentTab: string) => {
    try {
      if (currentTab === KEY_TAB.DPE) {
        const resultSVG = await mutateDPE({ id, type: currentTab });
        setResultatDPE(resultSVG);
      } else {
        const resultSVG = await mutateCO2({ id, type: currentTab });
        setResultatCO2(resultSVG);
      }
    } catch (e: any) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
        duration: 2,
      });
    } finally {
      if (currentTab === KEY_TAB.DPE) {
        setIsLoadingDPE(false);
      } else {
        setIsLoadingCO2(false);
      }
    }
  };

  const tabItems: TabsProps["items"] = [
    {
      key: KEY_TAB.DPE,
      label: (
        <span>
          En kWh/m<sup>2</sup>
        </span>
      ),
      children: (
        <div className="text-center">
          {isLoadingDPE ? (
            <div className="svg-image mx-auto">
              <Skeleton.Button active />
            </div>
          ) : isSuccessDPE && ResultatDPE ? (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                ResultatDPE || ""
              )}`}
              alt="result"
              className="mx-auto"
            />
          ) : isSuccessDPE && !ResultatDPE ? (
            <Empty imageStyle={{ height: 310, marginBottom: 0 }} />
          ) : null}
        </div>
      ),
    },

    {
      key: KEY_TAB.CO2,
      label: (
        <span>
          En CO<sub>2</sub>/m<sup>2</sup>
        </span>
      ),
      children: (
        <div className="text-center">
          {isLoadingCO2 ? (
            <div className="svg-image mx-auto">
              <Skeleton.Button active />
            </div>
          ) : isSuccessCO2 && ResultatCO2 ? (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                ResultatCO2 || ""
              )}`}
              alt="result"
              className="mx-auto"
            />
          ) : isSuccessCO2 && !ResultatCO2 ? (
            <Empty imageStyle={{ height: 310, marginBottom: 0 }} />
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="energy-home__resultats">
      <Tabs
        centered
        activeKey={tab}
        items={tabItems}
        onChange={handleChangeTab}
      />

      <div className="flex flex-col gap-1 w-[265px] mx-auto mt-2">
        {_.get(dataDynamicInfo, "under_40sqm.title") && (
          <div className="info-resultat-item flex justify-start items-center gap-2">
            <span>{dataDynamicInfo["under_40sqm"]?.title}</span>
            <InfoCircleOutlined onClick={() => handleViewInfo("under_40sqm")} />
          </div>
        )}
        {_.get(dataDynamicInfo, "maximised_GES.title") && (
          <div className="info-resultat-item flex justify-start items-center gap-2">
            <span>{dataDynamicInfo["maximised_GES"]?.title}</span>
            <InfoCircleOutlined
              onClick={() => handleViewInfo("maximised_GES")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultatTab;
