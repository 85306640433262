import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Button, Card, Empty, Flex } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import useCommonStore from "stores/useCommon";
import { ReactComponent as IconReliability } from "assets/images/reliability.svg";
import { MAP_ICON_FIABILITE } from "constants/icon";

const MAX_SHOW = 4;

const Fiabilite: FC = ({
  dataFiabilite,
  svgFiabilite,
  dataDynamicInfo,
  onSetDataModalFiabilite,
}: any) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const { setDynamicInfoKey, setIsShowModalDynamicInfo } = useCommonStore();

  useEffect(() => {
    setIsShowMore(false);
  }, [dataFiabilite]);

  const renderIssues = () => {
    const limit = !isShowMore
      ? _.get(dataFiabilite, "reliability.issues.n_displayed", MAX_SHOW)
      : null;

    const details = limit
      ? _.get(dataFiabilite, "reliability.issues.details", []).slice(0, limit)
      : _.get(dataFiabilite, "reliability.issues.details", []);

    return details.map((item: any, index: number) => (
      <li key={index} className="mb-1 text-[16px]">
        {item.details}
      </li>
    ));
  };

  const maxShow = _.get(
    dataFiabilite,
    "reliability.issues.n_displayed",
    MAX_SHOW
  );

  const numberOfDetail = _.get(
    dataFiabilite,
    "reliability.issues.details",
    []
  ).length;

  return (
    <Card className="mb-3">
      <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
        <IconReliability className="w-7 h-7 flex-shrink-0" />
        Fiabilité de mon DPE
      </h3>

      {!_.isEmpty(dataFiabilite) ? (
        <div className="energy-home__project-info__indice py-8">
          <div>
            <div className="flex flex-col items-center">
              <div className="flex gap-2">
                <p className="text-large-bold">Score de fiabilité</p>
                <InfoCircleOutlined
                  onClick={() =>
                    onSetDataModalFiabilite(
                      _.get(dataFiabilite, "reliability.consistency", null),
                      "consistency"
                    )
                  }
                />
              </div>
              <div className="fiabilite-number">
                {dataFiabilite?.reliability?.level} / 10
              </div>
            </div>

            {/* image indice */}
            <div className="flex justify-center mt-8">
              {svgFiabilite && (
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    svgFiabilite || ""
                  )}`}
                  alt="result"
                  className=""
                />
              )}
            </div>
          </div>

          <div className="anomalies px-2 pr-4">
            <div className="flex items-center gap-2 mb-2 text-justify">
              <span className="dot-large dot-issue">
                {_.get(dataFiabilite, "reliability.issues.number", "")}
              </span>
              <span className="text-large-bold">
                {_.get(dataFiabilite, "reliability.issues.label", "Anomalies")}
              </span>
              {!_.isEmpty(_.get(dataDynamicInfo, "anomaly")) && (
                <InfoCircleOutlined
                  onClick={() => {
                    setDynamicInfoKey("anomaly");
                    setIsShowModalDynamicInfo(true);
                    // handleSetDataModalFiabilite(
                    //   _.get(dataFiabilite, "reliability.issues", null),
                    //   "anomaly"
                    // )
                  }}
                />
              )}
            </div>
            <ul className="list-disc pl-5">{renderIssues()}</ul>
            {numberOfDetail > maxShow && (
              <div className="text-right">
                <Button
                  type="link"
                  className="underline text-[#125758] hover:!text-[#125758] text-[18px] font-medium"
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  {isShowMore ? "Voir moins" : "Voir plus"}
                </Button>
              </div>
            )}
          </div>

          <div className="px-2">
            <div className="flex items-center gap-2 mb-2">
              <span className="text-large-bold">
                {_.get(dataFiabilite, "reliability.areas.label", "Eléments")}
              </span>
              {!_.isEmpty(_.get(dataDynamicInfo, "element")) && (
                <InfoCircleOutlined
                  onClick={() => {
                    setDynamicInfoKey("elements");
                    setIsShowModalDynamicInfo(true);
                    // handleSetDataModalFiabilite(
                    //   _.get(dataFiabilite, "reliability.areas", null),
                    //   "areas"
                    // );
                  }}
                />
              )}
            </div>

            {_.get(dataFiabilite, "reliability.areas.details", []).map(
              (item: any, index: number) => {
                const Icon = MAP_ICON_FIABILITE[item.type];
                return (
                  <div className="flex items-center gap-1.5 mb-2" key={index}>
                    {Icon && <Icon className="w-8 h-8"  />}
                    <p className="text-[16px]">{item.label}</p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      ) : (
        <div>
          <Flex align="flex-start" justify="space-between">
            <p className="text-large-bold">Score de fiabilité</p>
            <div className="flex items-center">
              <span className="dot"></span>
              <span className="fiabilite-number">
                {dataFiabilite?.reliability?.level}
              </span>
            </div>
          </Flex>
          <Empty imageStyle={{ height: 250 }} />
        </div>
      )}
    </Card>
  );
};

export default Fiabilite;
