import { useMutation } from "@tanstack/react-query";
import { Offer } from "types/Stripe.type";
import API from "services/api";


export const useMutationPaymentIntent = () => {
    return useMutation({
        mutationFn: async (orderData: Offer) => await API.mock.postStripePaymentIntent(orderData),
        retry: 0,
    });
};