import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import PAGES from "routes/constants";
import "styles/stripe.scss";

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PAGES.home);
  }, [navigate]);

  return null;
}

export default NotFound;
