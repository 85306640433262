import _ from "lodash";
import {
  AppstoreOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  FundProjectionScreenOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import PAGES, { getDashboardURL } from "./constants";
// import { getProject } from "services/storages/projectStorage";
// import { getUserToken } from "services/storages/userStorage";
import { DynamicMenu } from "services/api/type/mock.type";

export enum MENU_NAVIGATION_VALUE {
  MAIN = "main",
  LIST = "list",
}

export const getMenuNavigation = (
  isHomePage: boolean = false,
  dynamicMenu: DynamicMenu | undefined
) => {
  // const isLastProject = getProject();
  // const isLogin = getUserToken();

  let MENU_NAVIGATION = dynamicMenu?.admin
    ? [
        {
          key: PAGES.home,
          icon: <AppstoreOutlined />,
          label: "Main",
        },
        {
          key: PAGES.list,
          icon: <UnorderedListOutlined />,
          label: "Projets",
        },
        {
          key: PAGES.reCalculate,
          icon: <CalculatorOutlined />,
          label: "re-calcul",
        },
        {
          key: PAGES.settings,
          icon: <SettingOutlined />,
          label: "Settings",
        },
      ]
    : [];

  if (!_.isEmpty(dynamicMenu?.projects)) {
    dynamicMenu?.projects.forEach((project) => {
      MENU_NAVIGATION.splice(1, 0, {
        key: getDashboardURL(project.ref_ademe),
        icon: <FundProjectionScreenOutlined />,
        label: project.label,
      });
    });
  }

  if (isHomePage) {
    MENU_NAVIGATION = MENU_NAVIGATION.filter(
      (item) => item.key !== PAGES.reCalculate
    );
  }

  return MENU_NAVIGATION;
};

export default PAGES;
