import React from "react";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Layout, Menu, Drawer, Button, Badge } from "antd";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useScroll, motion, useTransform } from "framer-motion";
import classNames from "classnames";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import InsightPage from "./pages/insight";
import useCommonStore from "./stores/useCommon";

import IconInsight from "assets/images/star-contact.png";
import LogoMain from "assets/images/logo-checkdpe.png";
import LogoMobile from "assets/images/logo-checkdpe-mobile.png";
import { ReactComponent as IconUser } from "assets/images/user.svg";
import { ReactComponent as IconInfo } from "assets/images/info-square.svg";
import { ReactComponent as IconWarning } from "assets/images/warning.svg";

import PAGES, { getMenuNavigation } from "./routes/menu";
import API from "./services/api";
import {
  clearUserInfo,
  clearUserToken,
  getUserInfo,
  getUserToken,
} from "./services/storages/userStorage";
import { cognitoUserPool } from "./config";
import QUERY_KEYS from "./services/api/queryKeys";
import useInsight from "./stores/useInsight";
import { BUTTON_ID } from "./constants/insight";
import { setLastAccessPage } from "services/storages/expiredStorage";

const MapIconAlert: any = {
  warning: IconWarning,
  info: IconInfo,
};

const { Content } = Layout;

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { id } = useParams();
  const queryClient = useQueryClient();

  // const containerRef = useRef<any>();
  const { scrollYProgress } = useScroll();
  const navigate = useNavigate();
  const location = useLocation();
  const { setButtonId } = useInsight();
  const [count, setCount] = useState(0);

  const isAllowedShowInsight = useMemo(() => {
    const paths = [PAGES.dashboard, PAGES.address, PAGES.upload];
    if (paths.some((path) => matchPath(path, location.pathname))) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const {
    isShowInsight,
    setIsShowInsight,
    isLoadedPage,
    setIsLoadedPage,
    alertTop,
    closeAlertTop,
  } = useCommonStore();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(location.pathname);
  const headerBg = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 0.75)"]
  );
  const headerFilter = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["blur(0)", "blur(15px)"]
  );
  const menuColor = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
  );

  const isLogin = getUserToken();
  const { data: dynamicMenu } = useQuery({
    queryKey: [QUERY_KEYS.GET_DYNAMIC_MENU],
    queryFn: async () => {
      const data = await API.mock.getDynamicMenu();
      return data;
    },
    retry: 0,
    enabled: !!isLogin,
  });

  const { mutateAsync: mutateChatInit, isPending: isPendingChatInit } =
    useMutation({
      mutationFn: (params: any) => API.mock.chatInit(params),
      retry: 0,
    });

  const { mutateAsync: mutateCloseAlertTopACK } = useMutation({
    mutationFn: (params: any) => API.mock.closeAlertTopACK(params),
  });

  const isHomePage = useMemo(() => {
    return !!matchPath(
      {
        path: PAGES.home,
        end: true,
      },
      location.pathname
    );
  }, [location.pathname]);

  useEffect(() => {
    setIsShowInsight(false);

    const navigation = getMenuNavigation(isHomePage, dynamicMenu);
    for (let item of navigation) {
      if (matchPath(item.key, location.pathname)) {
        setSelectedKeys(item.key);
        return;
      }
    }

    if (
      [PAGES.dashboard, PAGES.acheter, PAGES.vendre, PAGES.renover].some(
        (path) =>
          matchPath(
            {
              path,
              end: true,
            },
            location.pathname
          )
      )
    ) {
      setSelectedKeys(PAGES.home);
    } else {
      setSelectedKeys(location.pathname);
    }

    window.scrollTo({ top: 0 });
  }, [dynamicMenu, isHomePage, location.pathname, setIsShowInsight]);

  useEffect(() => {
    if (isAllowedShowInsight && id) {
      const evtSource = new EventSource(
        `https://api-dev.etiquettedpe.fr/sse/events?ref_ademe=${id}`
      );
      evtSource.onmessage = function (event) {
        try {
          const dataobj = JSON.parse(event.data);
          const data = JSON.parse(dataobj.data);
          console.log("evtSource", dataobj);
          setCount(data?.chat_counter || 0);
        } catch (e) {
          console.log("error", e);
        }
      };
      return () => {
        setCount(0);
        evtSource.close();
      };
    }
  }, [id, isAllowedShowInsight]);

  useEffect(() => {
    if (isTabletOrMobile) {
      setIsOpenMenu(false);
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (!(window as any).navigate) {
      (window as any).navigate = navigate;
    }
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((value) => {
      if (isLoadedPage && value !== 0) {
        setIsLoadedPage(false);
      }
      // console.log("Scroll progress:", value); // Logs scroll progress
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, [isLoadedPage, scrollYProgress, setIsLoadedPage]);

  const handleClickMenu = ({ key }: any) => {
    if (key === "signout") {
      const userInfo = getUserInfo();
      const userInfoAWS = cognitoUserPool.getCurrentUser();

      const user = new AmazonCognitoIdentity.CognitoUser({
        Username: userInfoAWS?.getUsername() || userInfo.username,
        Pool: cognitoUserPool,
      });

      clearUserInfo();
      clearUserToken();
      user.signOut();
      navigate(PAGES.login);
      setIsOpenMenu(false);
      return;
    }
    setIsOpenMenu(false);
    navigate(key);
  };

  const handleToInsight = () => {
    setIsShowInsight(!isShowInsight);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleOpenChatAI = async () => {
    try {
      setButtonId(BUTTON_ID.CONTACTEZ);
      await mutateChatInit({
        ref_ademe: id,
        from: "dashboard",
      });
      handleToInsight();
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleCloseAlertTop = async () => {
    try {
      closeAlertTop();
      await mutateCloseAlertTopACK({
        ref_ademe: id,
        banner_id: alertTop?.id,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleOpenMenu = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_DYNAMIC_MENU] });
    setIsOpenMenu(!isOpenMenu);
  };

  const isHomePageAndSubPage = useMemo(() => {
    if (
      [PAGES.home, PAGES.acheter, PAGES.vendre, PAGES.renover].some((path) =>
        matchPath(path, location.pathname)
      )
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const isLoginSignUp = useMemo(() => {
    if (
      [
        PAGES.login,
        PAGES.signup,
        PAGES.confirmationSignup,
        PAGES.forgotPassword,
      ].some((path) => matchPath(path, location.pathname))
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const antLayoutStyle = useMemo(() => {
    if (isLoginSignUp) {
      return {
        margin: "88px 0 0",
        padding: 0,
      };
    }

    if (!isHomePageAndSubPage) {
      return {
        margin: "0 0 24px",
        padding: 20,
        paddingTop: !_.isEmpty(alertTop) ? 162 : 88,
      };
    }
  }, [alertTop, isHomePageAndSubPage, isLoginSignUp]);

  const isEmptyProject = useMemo(() => {
    return _.isEmpty(dynamicMenu?.projects) && !dynamicMenu?.admin;
  }, [dynamicMenu?.admin, dynamicMenu?.projects]);

  const userInfoAWS = cognitoUserPool.getCurrentUser();
  const IconAlert = !_.isEmpty(alertTop) ? MapIconAlert[alertTop.type] : null;

  return (
    <div
      className={classNames("energy-app", {
        "energy-app--outisde": isLoginSignUp,
        "energy-app--alert-top": !_.isEmpty(alertTop),
      })}
    >
      <Layout>
        {!_.isEmpty(alertTop) && (
          <div
            className={classNames(
              "fixed flex items-center justify-center top-0 w-full py-4 z-[9999] min-h-[74px]",
              {
                "bg-[#418071ff]": alertTop.type === "warning",
                "bg-[#647fb3ff]": alertTop.type === "info",
              }
            )}
          >
            <div className="flex items-center justify-center gap-x-2 w-full">
              <IconAlert className="w-8 h-8 icon-alert" />

              <p className="text-white text-large-bold  alert-message text-[18px]">
                {alertTop.message || ""}
              </p>
            </div>

            {alertTop.closeable && (
              <svg
                stroke="#fff"
                fill="none"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="20px"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer absolute right-2 md:right-8 1/2"
                onClick={handleCloseAlertTop}
              >
                <path
                  d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                  fill="#fff"
                ></path>
              </svg>
            )}
          </div>
        )}
        <motion.div
          initial={{ backgroundColor: `rgba(255, 255, 255, 0)` }}
          style={{
            backgroundColor: isLoginSignUp
              ? "#fff"
              : isLoadedPage
              ? `rgba(255, 255, 255, 0)`
              : headerBg,
            backdropFilter: isLoginSignUp ? "none" : headerFilter,
          }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className={classNames("ant-layout-header", {
            // "header--fixed": isHomePageAndSubPage,
            "header--white": !isHomePageAndSubPage,
            "header--absolute-white": isLoginSignUp,
          })}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-x-3 sm:gap-x-2">
              {!isEmptyProject && (
                <motion.button
                  onClick={handleOpenMenu}
                  className="ant-btn ant-btn-text ant-btn-icon-only btn-burger"
                  style={{
                    fontSize: "16px",
                    width: isTabletOrMobile ? 32 : 64,
                    height: 64,
                    color: isHomePageAndSubPage ? menuColor : "#000",
                  }}
                >
                  <span className="ant-btn-icon">
                    <span className="anticon anticon-menu">
                      <MenuOutlined />
                    </span>
                  </span>
                </motion.button>
              )}

              <Link to={PAGES.home}>
                <motion.h1
                  className={classNames("logo", {
                    "ml-3 md:ml-[32px]": isEmptyProject,
                  })}
                  style={{
                    color: isHomePageAndSubPage ? menuColor : "#000",
                  }}
                >
                  {isTabletOrMobile ? (
                    <img src={LogoMobile} alt="logo" className="w-10" />
                  ) : (
                    <img
                      src={LogoMain}
                      alt="logo"
                      className="w-[200px] md:w-[230px]"
                    />
                  )}
                </motion.h1>
              </Link>
            </div>

            <div className="flex items-center gap-4">
              {isAllowedShowInsight && (
                <Button
                  className="flex items-center gap-x-2 px-4 h-8 p-0 btn-flex rounded-full md:min-w-[163px]"
                  style={{ background: "#3b8686" }}
                  onClick={handleOpenChatAI}
                >
                  {isPendingChatInit ? (
                    <LoadingOutlined className="text-[16px]" />
                  ) : (
                    <>
                      <Badge
                        count={count}
                        style={{ borderColor: "#ff4d4f", right: "-4px" }}
                        overflowCount={9}
                      >
                        <img
                          src={IconInsight}
                          alt="insight"
                          width={24}
                          className="cursor-pointer"
                        />
                        <span className="!hidden md:!inline text-white font-semibold md:text-[16px]">
                          Contactez nous
                        </span>
                      </Badge>
                    </>
                  )}
                </Button>
              )}

              {userInfoAWS ? (
                <Link to={PAGES.account}>
                  <Button className="rounded-full btn-login">
                    <p className="font-semibold text-[16px]">
                      {userInfoAWS.getUsername()?.slice(0, 1).toUpperCase()}
                    </p>
                  </Button>
                </Link>
              ) : (
                <Link to={PAGES.login} className="flex items-center gap-1">
                  <Button
                    className="rounded-[16px] btn-login"
                    onClick={() =>
                      setLastAccessPage(window.location.hash?.slice(1))
                    }
                  >
                    <IconUser className="w-5 h-5" style={{ fill: "black" }} />
                    <p className="hidden md:block font-semibold md:text-[16px]">
                      Se connecter
                      {/* {isLoginPage ? "Se connecter" : "Pas encore de compte ?"} */}
                    </p>
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </motion.div>

        <Content
          className={classNames({
            "content--homepage": isHomePageAndSubPage,
          })}
          style={{
            ...antLayoutStyle,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>

      {/* {isShowInsight && (
          <div className="insight-side">
            <InsightPage />
          </div>
        )} */}

      <Drawer
        title="checkDPE"
        placement="left"
        closable={false}
        onClose={handleCloseMenu}
        open={isOpenMenu}
        className="drawer-menu"
        width={250}
        rootClassName="drawer-custom"
      >
        <Menu
          mode="inline"
          onClick={handleClickMenu}
          selectedKeys={[selectedKeys]}
          items={getMenuNavigation(isHomePage, dynamicMenu)}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        onClose={() => setIsShowInsight(false)}
        open={isShowInsight}
        className="drawer-menu"
        width={isTabletOrMobile ? "90%" : "30%"} // changed: set width to 50% of the viewport
        rootClassName="drawer-custom"
      >
        {isShowInsight && <InsightPage />}
      </Drawer>
    </div>
  );
}

export default App;
