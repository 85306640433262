import { createPaymentIntentEndpoint } from "constants/stripe";

var homeUrl = "https://api-dev.etiquettedpe.fr/home";
var homeSubUrl = "https://api-d.ledpe.fr/home-sub";

export const paths = {
  mockData() {
    return `https://pst2ptf774f5e6tabql7g4tle40dyetm.lambda-url.eu-west-1.on.aws`;
  },
  modalData() {
    return `https://tnorlolbmzkwo6gxf5u5awukke0senxc.lambda-url.eu-west-1.on.aws`;
  },
  autoCompleteSearch(search: string) {
    return `https://api-adresse.data.gouv.fr/search/?q=${search}`;
  },
  listingTable() {
    return "https://akwaiji6qo3z5qy2tyeq4amvh40uhqfc.lambda-url.eu-west-1.on.aws";
  },
  getDataUpload(fileName: string) {
    return `https://gokdffn62pntatmenhosazmf540bgpmt.lambda-url.eu-west-1.on.aws/?file_name=${fileName}`;
  },
  getRefAdemeFromDataUpload() {
    return `https://7go35nkmxug47vxwylblknm5mm0iwhhl.lambda-url.eu-west-1.on.aws/`;
  },
  getHomeUploadData() {
    return `${homeUrl}/home`;
  },
  getResultat() {
    return `${homeUrl}/svg_result`;
  },
  getGEOMap() {
    return "https://gist.githubusercontent.com/wavded/1200773/raw/e122cf709898c09758aecfef349964a8d73a83f3/sample.json";
  },
  submitResultat() {
    return `${homeUrl}/submit`
  },
  getDataFiabilite() {
    return `${homeUrl}/reliability` ;
  },
  getSVGFiabilite() {
    return `${homeUrl}/svg_reliability`;
  },
  getResultAutocomplete() {
    return `${homeUrl}/search_dpes_map`;
  },
  getReCalCulateResult() {
    return "https://vwpw34wpop5cbyr22frtfoppzy0nzlxl.lambda-url.eu-west-1.on.aws/?id=dd";
  },
  getControlsResult() {
    return "https://api-d.ledpe.fr/backoffice/dpe_list";
  },
  postSelectedRowControl() {
    return "https://api-d.ledpe.fr/backoffice/send";
  },
  sendDataControl(control: string) {
    return `https://api-d.ledpe.fr/backoffice/${control}`;
  },
  searchDpesMap() {
    return `${homeUrl}/search_dpes_map`;
  },
  chainlit() {
    return "https://chainlit27389273892738927hdjhceziuhdcez7837298.ledpe.fr/";
  },
  getSuggestionRenovation() {
    return `${homeSubUrl}/suggested_renovation`;
  },
  getGraphePrice() {
    return `${homeUrl}/svg_budget`;
  },
  chatInit() {
    return `${homeUrl}/chat_init`;
  },
  getAccount() {
    return `${homeUrl}/account`;
  },
  getReliabilityRenovation(query: string) {
    return `${homeSubUrl}/reliability_renovation?q=${query}`;
  },
  getDynamicMenu() {
    return `${homeUrl}/context`;
  },
  postStripePaymentIntent() {
    return `${createPaymentIntentEndpoint}`;
  },
  getDynamicInformationResultat() {
    return `${homeUrl}/information`;
  },
  closeAlertTopACK() {
    return `${homeUrl}/ack_banner`;
  },
  ackOverlayPackage() {
    return `${homeUrl}/ack_token`;
  },
  getSvgLeakage() {
    return `${homeUrl}/svg_leakage`;
  },
};
