import Cover2 from "assets/images/cover-2.jpg";
import Cover3 from "assets/images/cover-3.jpg";
import Cover4 from "assets/images/cover-4.jpg";
import Cover5 from "assets/images/cover-5.jpg";
import Cover6 from "assets/images/cover-6.jpg";
import Cover7 from "assets/images/cover-7.jpg";

export const getLandingLocale = () => {
  return {
    top: {
      all: {
        title: "Reprenez le contrôle de votre DPE",
        description:
          "Le DPE (Diagnostic de Performance Energétique) est un diagnostic obligatoire qui indique le niveau de performance énergétique des logements.\n\n Bien utilisé il doit être un indicateur clé pour comprendre la qualité de votre logement.\n checkDPE est un acteur indépendant engagé dans la transition énergétique qui vous aide à comprendre votre DPE : sa fiabilité, les résultats qui vous sont proposés, ainsi que les recommandations d’amélioration",
        bg_images_xl: [],
        bg_images_m: [],
      },
      acheter: {
        title: "checkDPE Achat",
        description:
          "Vous êtes en train d’acheter un bien et voulez comprendre le DPE de votre futur bien  et son niveau de fiabilité",
        bg_images_xl: [],
        bg_images_m: [],
      },
      vendre: {
        title: "checkDPE Location",
        description:
          "Vous êtes propriétaire bailleur et voulez gagner 1 ou 2 lettres pour pouvoir louer votre bien",
        bg_images_xl: [],
        bg_images_m: [],
      },
      renover: {
        title: "checkDPE Réno",
        description:
          "Vous êtes propriétaire et  rénovez votre logement et vous voulez mesurer l’impact en terme de lettre DPE",
        bg_images_xl: [],
        bg_images_m: [],
      },
    },
    personae: {
      acheter: {
        title:
          "Vous êtes en train d’acheter un bien et voulez comprendre le DPE et son niveau de fiabilité",
        description:
          "Le DPE de votre futur logement est une mine d’information pour comprendre la qualité du bien que vous souhaitez acheter et évaluer le niveau des travaux nécessaires pour le rénover dans les prochains mois.checkDPE vous propose une lecture éclairée sur :\n-La fiabilité du diagnostic\n-Les résultats et le niveau de performance de votre futur bien\n-Les recommandations de travaux selon vos besoins",
        bg_image: Cover2,
      },
      vendre: {
        title:
          "Vous êtes propriétaire bailleur et voulez gagner 1 ou 2 lettres pour pouvoir louer votre bien",
        description:
          "Le DPE de votre bien en location est une mine d’information pour évaluer le niveau des travaux nécessaires pour le rénover dans les prochains mois.checkDPE vous propose une lecture éclairée sur :\n-La fiabilité du diagnostic\n-Les résultats et le niveau de performance de votre bien\nL’ajustement des recommandations en fonction de votre budget et ce que vous souhaitez faire dans les prochaines années",
        bg_image: Cover3,
      },
      renover: {
        title:
          "Vous êtes propriétaire et rénovez votre logement et vous voulez mesurer l’impact en terme de lettre DPE",
        description:
          "Le DPE doit être un indicateur clé pour guider votre rénovation. La lettre DPE après rénovation devient très importantes et contribue à la valorisation de ce que va apporter votre projet de rénovation.checkDPE vous propose une lecture éclairée sur :\n-La fiabilité du diagnostic initial\n-Les niveaux d’amélioration du DPE (gain de lettre) en fonction de votre programme\n-L’ajustement des recommandations en fonction de vos objectifs",
        bg_image: Cover4,
      },
    },
    banner_2: {
      all: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover5,
      },
      vendre: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover6,
      },
      acheter: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover7,
      },
      renover: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover3,
      },
    },
  };
};
