import { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import PAGES from "routes/constants";
import { setSignupEmail } from "services/storages/userStorage";

interface PaymentFormProps {
    amount: number | undefined;
}


const PaymentForm: React.FC<PaymentFormProps> = ({amount}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [signupEmail, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);


    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setIsEmailValid(validateEmail(newEmail));
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setSignupEmail(signupEmail)

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/#${PAGES.paymentComplete}`,
                receipt_email: signupEmail,
                payment_method_data: {},
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            console.error(message);
            setMessage(error.message || "An unexpected error occurred.");
        } else {
            console.error("An unexpected error occurred.");
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: "accordion"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <div className="email-input-container mb-4">
                <label htmlFor="email-input" className="block text-sm font-medium text-gray-700 mb-2">
                    Veuillez saisir votre adresse email
                </label>
                <input
                    id="email-input"
                    type="email"
                    value={signupEmail}
                    onChange={handleEmailChange}
                    className={`w-full p-3 border ${!isEmailValid && signupEmail ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                    placeholder="Email"
                    required
                />
                {!isEmailValid && signupEmail && (
                    <p className="text-red-500 text-sm mt-1">Veuillez rentrer un email valide.</p>
                )}
            </div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
                Veuillez saisir vos informations de carte bancaire
            </label>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <input
                type="checkbox"
                id="terms"
                checked={isTermsAccepted}
                onChange={(e) => setIsTermsAccepted(e.target.checked)}
                className="mr-2"
            />
            <label htmlFor="terms" className="text-sm text-gray-600">
                En cochant cette case vous acceptez les{' '}
                <a
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700 underline"
                >
                    conditions générales de vente et d'utilisation
                </a>
                .
            </label>
            <button disabled={isLoading || !stripe || !elements || !signupEmail || !isEmailValid || !isTermsAccepted} id="submit" className="stripe-button">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Payer " + amount +" €"}
                </span>
            </button>
        </form>
    );
}

export default PaymentForm;