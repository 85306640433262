import _ from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Tabs, TabsProps } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import usePackage from "stores/usePackage";
import { ReactComponent as IconPencil } from "assets/images/pencil.svg";
import { ReactComponent as IconWall } from "assets/images/renovation/wall.svg";
import { ReactComponent as IconAir } from "assets/images/renovation/air-conditioner.svg";
import { ReactComponent as IconDoor } from "assets/images/renovation/door.svg";
import { ReactComponent as IconHouse } from "assets/images/renovation/house.svg";
import { ReactComponent as IconWater } from "assets/images/renovation/water.svg";
import { ReactComponent as IconRise } from "assets/images/renovation/rise.svg";
import { ReactComponent as IconEuro } from "assets/images/renovation/euro.svg";
import { ReactComponent as IconBar } from "assets/images/renovation/bar.svg";
import { MAP_COLOR_DPE } from "constants/index";
import PAGES from "routes/constants";

// import { useChainlitMutation } from "../../mutations/useChainlitMutation";
import { useSvgLeakageMutation } from "../../mutations/useSvgLeakageMutation";
import { useGetReliabilityQuery } from "../../queries/useGetReliabilityQuery";

import "styles/dashboard.scss";
import { HouseConstruction } from "assets/images";

interface Props {
  id: string;
  dataSuggestion: any;
  dataHomeUploaded: any;
  openChainLit: () => void;
}

const MAP_ICON: any = {
  wall: IconWall,
  ceiling: IconHouse,
  heating: IconAir,
  doorswindows: IconDoor,
  hotwater: IconWater,
};

const SuggestionRenovation: FC<Props> = ({
  id,
  dataSuggestion,
  dataHomeUploaded,
  openChainLit,
}) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("");
  // const [svgLeakage, setSvgLeakage] = useState<string | null>(null);
  const { redirectUrl, setRedirectUrl } = usePackage();

  // const { mutateAsync } = useChainlitMutation();
  const { data: reliabilities } = useGetReliabilityQuery(id);
  const { mutateAsync: mutateSvg } = useSvgLeakageMutation();

  const isConstruction = reliabilities?.offer === "Construction";
  const isPending = reliabilities?.guidance_status === "pending";

  const handleClickDemarrer = () => {
    setRedirectUrl(window.location.hash.slice(1));
    navigate(PAGES.stripe, {
      state: {
        offerId: "3",
        refAdeme: dataHomeUploaded.ademe,
        address: dataHomeUploaded.project_details.address,
      },
    });
  };

  // const handleopenChainlit = async () => {
  //   await mutateAsync({
  //     ref_ademe: id,
  //     from: "control_renovation",
  //     from_details: {},
  //   });
  //   openChainLit();
  // };

  useEffect(() => {
    if (!_.isEmpty(dataSuggestion)) {
      const activeTab = dataSuggestion.find((item: any) => item.recommended);
      setTab(activeTab.iter);
    }
  }, [dataSuggestion]);

  useEffect(() => {
    const vosScenarios = document.getElementById("vos-scenarios");
    if (redirectUrl && vosScenarios) {
      vosScenarios.scrollIntoView();
      setRedirectUrl("");
    }
  }, [redirectUrl, setRedirectUrl]);

  useEffect(() => {
    // activate this when login is fixed
    // mutateSvg({
    //   id,
    // }).then((data) => setSvgLeakage(data));
  }, [id, mutateSvg]);

  const tabMapItems: TabsProps["items"] = dataSuggestion.map(
    (tabContent: any) => ({
      key: tabContent.iter,
      label: `Scénario ${tabContent.iter}${
        tabContent.recommended ? " (recommandé)" : ""
      }`,
      children: (
        <div>
          <h4 className="font-bold text-[32px] mb-6">
            Scénario {tabContent.iter} proposé par checkDPE
          </h4>

          <div className="flex flex-col gap-y-6">
            {!_.isEmpty(tabContent.projects) &&
              tabContent.projects
                .slice(0, isConstruction ? 2 : tabContent.projects.length)
                .map((item: any, index: number) => {
                  const Icon = MAP_ICON[item.object];
                  return (
                    <div
                      key={index}
                      className={classNames("suggestion-item", {
                        active: item.hightlight,
                      })}
                    >
                      <div className="flex items-center justify-between flex-wrap gap-y-2">
                        <p className="flex items-center gap-x-2.5 text-[20px] font-bold">
                          {Icon && <Icon />}
                          <span>{item.title}</span>
                        </p>

                        <div className="flex items-center gap-x-6">
                          <div className="flex items-center">
                            <span className="font-semibold text-[16px] mr-1">
                              Gain
                            </span>
                            {[...new Array(4)].map((__, index) => (
                              <IconRise
                                key={index}
                                className={classNames("icon-stat", {
                                  "icon-stat--active": item.gain >= index + 1,
                                })}
                              />
                            ))}
                          </div>

                          <div className="flex items-center">
                            <span className="font-semibold text-[16px] mr-1">
                              Prix
                            </span>
                            {[...new Array(4)].map((__, index) => (
                              <IconEuro
                                key={index}
                                className={classNames("icon-stat", {
                                  "icon-stat--active": item.cost >= index + 1,
                                })}
                              />
                            ))}
                          </div>
                        </div>
                      </div>

                      {!_.isEmpty(item.description) &&
                        item.description.map((desc: string, index: number) => (
                          <p className="text-[20px] mt-1" key={index}>
                            {desc}
                          </p>
                        ))}
                    </div>
                  );
                })}
          </div>
        </div>
      ),
    })
  );

  const mapColor = (value: string) => {
    switch (value) {
      case "error":
        return "bg-red-400";
      case "warning":
        return "bg-orange-400";
      case "info":
        return "bg-green-400";
    }
  };

  const selectedTabContent = useMemo(() => {
    return dataSuggestion.find((item: any) => item.iter === tab);
  }, [dataSuggestion, tab]);

  return (
    <div className="energy-home__suggestion-renovation">
      {(isConstruction || isPending) && (
        <Card className="mb-3 relative">
          <div className="bloc-container">
            <div className="text-container">
              <h3 className="title">
                <IconPencil className="flex-shrink-0" />
                <span>Les travaux pour améliorer mon DPE</span>
              </h3>

              <div className="description-container">
                <div className="text-justify text-medium mt-1">
                  Dans votre DPE vous trouverez 1 ou 2 scénarios de travaux pour
                  améliorer la performance de votre logement (vous pouvez les
                  consulter en p.5 du rapport pdf de votre DPE).
                </div>
                <div className="text-justify text-medium mt-1">
                  Si vous le souhaitez, <b>checkDPE</b> vous propose, en
                  complément de ce rapport fiabilité, de construire un scénario
                  adapté à votre besoin (gain de lettre, budget, ...).
                </div>
                <div className="text-justify text-medium mt-1">
                  Pour démarrer votre projet avec <b>checkDPE</b> et souscrire
                  au complément trauvaux de l'offre Fiabilité, veuillez cliquer
                  sur le bouton ci-dessous:
                </div>
              </div>
            </div>

            <div className="house-icon">
              <img
                src={HouseConstruction}
                alt="House Construction"
                className="w-44 h-52"
              />
            </div>
          </div>

          {isConstruction && (
            <div className="construction-container border-[3px] border-green rounded-[16px] p-4 mt-4">
              <div className="title text-center">
                <span className="text-gray-400 font-extrabold">Fiabilité</span>
                &nbsp;
                <span className="text-green font-extrabold">
                  + Complément travaux
                </span>
              </div>

              <div className="subTitle font-extrabol text-gray-800 text-center">
                + 65 €
              </div>

              <div className="list">
                <ul className="text-gray-500">
                  <li className="flex items-center space-x-2">
                    <span className="text-green-500">✔</span>
                    <span className="text-[16px]">
                      Score de fiabilité de mon DPE
                    </span>
                  </li>
                  <li className="flex items-center space-x-2 mt-2">
                    <span className="text-green-500">✔</span>
                    <span className="text-[16px]">
                      Identification des anomalies
                    </span>
                  </li>
                  <li className="flex items-center space-x-2 mt-2">
                    <span className="text-green-500">✔</span>
                    <span className="text-[16px]">
                      Comparaison de mon DPE aux logements similaires
                    </span>
                  </li>
                </ul>

                <ul
                  className="extra-list font-extrabold"
                  style={{ marginTop: 0 }}
                >
                  <li className="flex items-center space-x-2 text-[16px]">
                    <span className="text-green-500">✔</span>
                    <span>Proposition de travaux adaptée à mon projet</span>
                  </li>
                </ul>
              </div>

              <div className="description">
                <div className="mb-5 text-center font-extrabold text-[15px]">
                  Cette offre vous permet après avoir mesuré le niveau de
                  fiabilité de votre DPE, de vous projeter dans des travaux
                  permettant l'amélioration de votre logement selon votre projet
                  (gain de lettres, budget).
                </div>

                <div
                  className="flex items-center justify-center bg-bgPrimary text-white font-bold px-3 py-1 rounded-lg cursor-pointer mx-[20%] xl:mx-[25%]"
                  onClick={handleClickDemarrer}
                >
                  <span className="xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] xs:text-[12px] text-center">
                    Démarrez mon projet avec checkDPE
                  </span>
                  <IconBar className="h-[30%] w-[30%] xl:h-[12%] xl:w-[12%]" />
                </div>
              </div>
            </div>
          )}

          {isPending && (
            <div className="pending-container border-[3px] border-green rounded-[16px] p-4 flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <IconBar className="h-[15%] w-[15%] xl:h-[12%] xl:w-[12%]" />
              </div>
              <div className="text-center font-extrabold text-[15px] mb-5">
                Votre scénario travaux est en cours de réalisation. Dès que
                c'est prêt vous revevrez une notification par mail et les
                travaux recommandés s'afficheront ici
              </div>
            </div>
          )}
        </Card>
      )}

      {!isPending && (
        <Card className="mb-3 relative">
          {isConstruction && (
            <div className="overlay-2">
              <div className="top-[45%] left-[25%] w-[50%] text-center absolute border border-gray-400 text-gray-400 text-[22px] py-3 rotate-[-30deg]">
                Données Fictives
              </div>
            </div>
          )}
          <div className="energy-home__suggestion-renovation relative z-10">
            {!isConstruction && (
              <>
                <div className="flex items-center justify-between mb-3">
                  <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
                    <IconPencil className="flex-shrink-0" />
                    <span>Les travaux pour améliorer mon DPE</span>
                  </h3>

                  <InfoCircleOutlined style={{ fontSize: 24 }} />
                </div>

                <div className="flex items-center mb-3 justify-between">
                  <div>
                    <h5>Scénario proposé par CheckDPE</h5>
                    <div className="flex items-center justify-between">
                      <div className="text-[18px]">
                        Vous souhaitez bénéficier d'une proposition de travaux
                        adaptée à votre projet
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-5">
                  <h5>Les scénarios de mon DPE</h5>
                  <div className="flex items-center gap-2">
                    <div className="text-[18px]">
                      Qualité des scénarios proposés
                    </div>
                    <div
                      className={`w-4 h-4 mt-1 rounded-full ${mapColor(
                        reliabilities?.reliability.type || "info"
                      )}`}
                    ></div>
                  </div>

                  <ul className="mt-2 list-disc pl-5">
                    {reliabilities?.reliability?.issues?.details?.map(
                      (reliability, index) => (
                        <li key={index}>
                          <div className="text-[16px]">
                            {reliability.detail}
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="text-[16px]">
                              {reliability.label}
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </>
            )}

            <div className="energy-home__suggestion-renovation__tabs">
              <Row gutter={[24, 24]}>
                <Col
                  span={24}
                  order={2}
                  lg={{
                    span: 15,
                    order: 2,
                  }}
                  xl={{
                    span: 17,
                    order: 1,
                  }}
                >
                  <Tabs
                    centered
                    activeKey={tab}
                    items={tabMapItems}
                    onChange={setTab}
                  />
                </Col>
                <Col
                  span={24}
                  order={1}
                  lg={{
                    span: 9,
                    order: 2,
                  }}
                  xl={{
                    span: 7,
                    order: 2,
                  }}
                >
                  {selectedTabContent && (
                    <div className="energy-home__montant">
                      <p className="flex items-center font-bold text-[24px] gap-x-1">
                        <IconEuro />
                        Montant des travaux
                      </p>
                      <p className="text-[20px] pl-7">
                        Entre {selectedTabContent.estimated_cost[0]}€ et{" "}
                        {selectedTabContent.estimated_cost[1]}€
                      </p>

                      <p className="flex items-center font-bold text-[24px] gap-x-1 mt-4">
                        <IconRise />
                        Passez de la lettre
                      </p>

                      <div className="flex items-center gap-x-[20px] pl-7 mt-2">
                        <div
                          className="montant-label"
                          style={{
                            background:
                              MAP_COLOR_DPE[
                                _.get(selectedTabContent, "gain.0", "")
                              ],
                          }}
                        >
                          {_.get(selectedTabContent, "gain.0", "")}
                        </div>
                        <span
                          className="text-[32px]"
                          style={{ color: "#414141" }}
                        >
                          à
                        </span>
                        <div
                          className="montant-label"
                          style={{
                            background:
                              MAP_COLOR_DPE[
                                _.get(selectedTabContent, "gain.1", "")
                              ],
                          }}
                        >
                          {_.get(selectedTabContent, "gain.1", "")}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <Button className="btn-demarrer" onClick={handleClickDemarrer}>
          Démarrer une conversation
          </Button> */}
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default SuggestionRenovation;
