import * as mock from "./module/mock.api";
import baseApi, { HEADER_KEY } from "./base.api";
import { getToken, getUserToken, getLastIntentId } from "../storages/userStorage";

if (getUserToken()) {
  baseApi.setToken(getUserToken());
}

if (getToken()) {
  baseApi.setDefaultHeader(HEADER_KEY.X_AUTHORIZTION, getToken());
}

if (getLastIntentId()) {
  baseApi.setDefaultHeader(HEADER_KEY.X_INTENT_ID, getLastIntentId());
}

const apis = {
  mock,
};

export default apis;
