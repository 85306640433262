import _ from "lodash";
import { FC } from "react";
import { Modal } from "antd";
import useCommonStore from "stores/useCommon";

import "styles/dashboard.scss";

const ModalDynamicInformation: FC = ({ dataDynamicInfo }: any) => {
  const { dynamicInfoKey, isShowModalDynamicInfo, setIsShowModalDynamicInfo } =
    useCommonStore();

  return (
    <Modal
      title={
        dynamicInfoKey ? _.get(dataDynamicInfo, `${dynamicInfoKey}.header`) : ""
      }
      open={isShowModalDynamicInfo}
      centered
      onCancel={() => setIsShowModalDynamicInfo(false)}
      onOk={() => setIsShowModalDynamicInfo(false)}
      cancelButtonProps={{ className: "hidden" }}
    >
      <div>
        <p>
          {dynamicInfoKey
            ? _.get(dataDynamicInfo, `${dynamicInfoKey}.details`, "")
            : ""}
        </p>
      </div>
    </Modal>
  );
};

export default ModalDynamicInformation;
