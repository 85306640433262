import baseApi from "../base.api";
import { paths } from "../paths";
import { DynamicMenu } from "../type/mock.type";
import { Offer } from "types/Stripe.type";

export const getMockData = () => {
  return baseApi.get(paths.mockData());
};

export const autoCompleteSearch = (search: string) => {
  return baseApi.get(paths.autoCompleteSearch(search));
};

export const getModalData = () => {
  return baseApi.get(paths.modalData());
};

export const getListingData = () => {
  return baseApi.get(paths.listingTable());
};

export const getDataUpload = (fileName: string) => {
  return baseApi.get(paths.getDataUpload(encodeURIComponent(fileName)));
};

export const getRefAdemeFromDataUpload = (metadata_id: string) => {
  return baseApi.post(paths.getRefAdemeFromDataUpload(), { metadata_id });
};

export const uploadFile = (path: string, data: any) => {
  return baseApi.postFormData(path, data);
};

export const getHomeUploadData = (data: any) => {
  return baseApi.post(paths.getHomeUploadData(), data);
};

export const getResultat = (data: any) => {
  return baseApi.post(paths.getResultat(), data);
};

export const getGEOMap = () => {
  return baseApi.get(paths.getGEOMap());
};

export const submitResultat = (ref_ademe: string) => {
  return baseApi.post(paths.submitResultat(), { ref_ademe });
};

export const getDataFiabilite = (id: string) => {
  return baseApi.get(paths.getDataFiabilite(), { q: id });
};

export const getSVGFiabilite = (payload: any) => {
  return baseApi.post(paths.getSVGFiabilite(), payload);
};

export const getResultAutocomplete = (payload: any) => {
  return baseApi.post(paths.getResultAutocomplete(), payload);
};

export const getReCalCulateResult = () => {
  return baseApi.get(paths.getReCalCulateResult());
};

export const getControlsResult = (q: string, from: string) => {
  return baseApi.get(paths.getControlsResult(), { q, from });
};

export const postSelectedRowControl = (params: any) => {
  return baseApi.post(paths.postSelectedRowControl(), params);
};
export const sendDataControl = (control: string, params: any) => {
  return baseApi.post(paths.sendDataControl(control), params);
};

export const searchDpesMap = (params: any) => {
  return baseApi.post(paths.searchDpesMap(), params);
};

export const getSuggestionRenovation = (ref_ademe: string) => {
  return baseApi.get(paths.getSuggestionRenovation(), { ref_ademe });
};

export const getGraphePrice = (ref_ademe: string) => {
  return baseApi.post(paths.getGraphePrice(), { ref_ademe });
};

export const chatInit = (params: any) => {
  return baseApi.post(paths.chatInit(), params);
};

export const getDynamicMenu = (): Promise<DynamicMenu> => {
  return baseApi.get(paths.getDynamicMenu());
};

export const postStripePaymentIntent = (orderData: Offer): Promise<any> => {
  return baseApi.post(paths.postStripePaymentIntent(), orderData);
};

export const getDynamicInformationResultat = (
  ref_ademe: string
): Promise<any> => {
  return baseApi.get(paths.getDynamicInformationResultat(), {
    ref_ademe,
  });
};

export const closeAlertTopACK = (params: any) => {
  return baseApi.post(paths.closeAlertTopACK(), params);
};

export const ackOverlayPackage = (params: any) => {
  return baseApi.post(paths.ackOverlayPackage(), params);
};

export const getReliability = (query: string) =>
  baseApi.get(paths.getReliabilityRenovation(query));

export const getSvgLeakage = (payload: any) =>
  baseApi.post(paths.getSvgLeakage(), payload);
