import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChainlitAPI, ChainlitContext } from "@chainlit/react-client";
import { RecoilRoot } from "recoil";

import { router } from "routes";
import { paths } from "services/api/paths";
import "styles/global.scss";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
  gtmId: 'GTM-PQBNZKFJ'
}
TagManager.initialize(tagManagerArgs)


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const apiClient = new ChainlitAPI(paths.chainlit(), "webapp");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <div>
    <QueryClientProvider client={queryClient}>
      <ChainlitContext.Provider value={apiClient}>
        <RecoilRoot>
          <ConfigProvider theme={{ hashed: false }}>
            <RouterProvider router={router} />
          </ConfigProvider>
        </RecoilRoot>
      </ChainlitContext.Provider>
    </QueryClientProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
