import _ from "lodash";
import {
  ComponentProps,
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import * as d3 from "d3";

import { MAP_COLOR_DPE } from "constants/index";
import "styles/dashboard.scss";

interface Props extends ComponentProps<any> {
  dataHomeUploaded: any;
  isPendingUploaded: boolean;
}

const CHART_HEIGHT = 190;

const DistributionChart: FC<Props> = forwardRef(
  ({ dataHomeUploaded, isPendingUploaded }, ref) => {
    const barChartRefInteger = useRef<any>();
    const barChartRefPercent = useRef<any>();
    const maxRef = useRef<number>(0);
    const maxRefPercent = useRef<number>(0);

    const [convertedDataPercent, setDataChartPercent] = useState<any[]>([]);
    const [convertedDataInteger, setDataChartInteger] = useState<any[]>([]);

    useEffect(() => {
      if (dataHomeUploaded) {
        const distribution = _.get(dataHomeUploaded, "distribution.values", {});
        const distribution_neighbors = _.get(
          dataHomeUploaded,
          "distribution_neighbors.values",
          {}
        );

        const convertedDataPercent = Object.keys(distribution).map(
          (key, index) => {
            return {
              label: key,
              value: distribution[key] * 100,
              color: MAP_COLOR_DPE[key],
              highlight: key === (dataHomeUploaded as any)?.results?.dpe_letter,
            };
          }
        );
        const convertedDataInteger = Object.keys(distribution_neighbors).map(
          (key, index) => {
            return {
              label: key,
              value: distribution_neighbors[key],
              color: MAP_COLOR_DPE[key],
              highlight: key === (dataHomeUploaded as any)?.results?.dpe_letter,
            };
          }
        );

        const max = _.maxBy(convertedDataInteger, (o) => o.value)?.value;
        maxRef.current = max;

        const maxPercent =
          _.maxBy(convertedDataPercent, (o) => o.value)?.value || 0;
        maxRefPercent.current = maxPercent;

        setDataChartPercent(convertedDataPercent);
        setDataChartInteger(convertedDataInteger);
      }
    }, [dataHomeUploaded]);

    useEffect(() => {
      drawD3();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertedDataPercent, convertedDataInteger, isPendingUploaded]);

    useImperativeHandle(ref, () => ({
      drawD3() {
        drawD3();
      },
    }));

    const drawD3 = () => {
      if (!_.isEmpty(convertedDataPercent) && !isPendingUploaded) {
        drawNewChartPercent();
      }

      if (!_.isEmpty(convertedDataInteger) && !isPendingUploaded) {
        drawNewChartInteger();
      }
    };

    const drawNewChartInteger = () => {
      const chart = d3.select(barChartRefInteger.current);
      chart.selectAll("div").remove();

      chart
        .selectAll(".bar")
        .data(convertedDataInteger)
        .enter()
        .append("div")
        .attr("class", "bar")
        .style("height", (d) => {
          return `${
            d.value === maxRef.current
              ? CHART_HEIGHT
              : (d.value / maxRef.current) * CHART_HEIGHT
          }px`;
        })
        .style("background-color", (d) => d.color)
        .style("border", (d) => (d.highlight ? "2px solid black" : "none"))
        .each(function (d) {
          d3.select(this)
            .append("div")
            .attr("class", "bar-label")
            .text(d.value);

          d3.select(this)
            .append("div")
            .attr("class", "indicator")
            .html(d.label);

          if (d.highlight) {
            d3.select(this)
              .append("div")
              .attr("class", "indicator-triangle")
              .html(
                '<div class="triangle"><div class="inner-triangle"></div></div>'
              );
          }
        });
    };

    const drawNewChartPercent = () => {
      const chart = d3.select(barChartRefPercent.current);
      chart.selectAll("div").remove();

      chart
        .selectAll(".bar")
        .data(convertedDataPercent)
        .enter()
        .append("div")
        .attr("class", "bar")
        .style("height", (d) => {
          return `${
            d.value === maxRefPercent.current
              ? CHART_HEIGHT
              : (d.value / maxRefPercent.current) * CHART_HEIGHT
          }px`;
        })
        .style("background-color", (d) => d.color)
        .style("border", (d) => (d.highlight ? "2px solid black" : "none"))
        .each(function (d) {
          d3.select(this)
            .append("div")
            .attr("class", "bar-label")
            .text(`${_.floor(d.value, 0)}%`);

          d3.select(this)
            .append("div")
            .attr("class", "indicator")
            .html(d.label);

          if (d.highlight) {
            d3.select(this)
              .append("div")
              .attr("class", "indicator-triangle")
              .html(
                '<div class="triangle"><div class="inner-triangle"></div></div>'
              );
          }
        });
    };

    return (
      <div className="energy-home__distribution">
        <div>
          <div className="flex justify-between">
            <div>
              <h3 className="text-xl font-bold">
                {_.get(dataHomeUploaded, "distribution_neighbors.title", "")}
              </h3>
              <p>
                {_.get(dataHomeUploaded, "distribution_neighbors.subtitle", "")}
              </p>
            </div>
          </div>
          <div className="bar-chart" ref={barChartRefInteger}></div>
        </div>

        <div className="mt-4">
          <div className="flex justify-between">
            <div>
              <h3 className="text-xl font-bold  ">
                {_.get(dataHomeUploaded, "distribution.title", "")}{" "}
              </h3>
              <p>{_.get(dataHomeUploaded, "distribution.subtitle", "")}</p>
            </div>
          </div>
          <div className="bar-chart" ref={barChartRefPercent}></div>
        </div>
      </div>
    );
  }
);

export default DistributionChart;
