interface StepProps {
    stepNames: string[];
}

export const Steps: React.FC<StepProps> = ({ stepNames }) => {
    const getStepStyle = (currentStep: string) => {
        return stepNames.includes(currentStep) ? 
            "w-8 h-8 bullet bg-[#15585a] rounded-full flex items-center justify-center z-10" :
            "w-8 h-8 border-2 bullet rounded-full flex items-center justify-center bg-white z-10";
    };

    const getTextStyle = (currentStep: string) => {
        return stepNames.includes(currentStep) ? "text-white text-sm" : "bullet text-sm";
    };

    return(
        <div className="flex justify-center mb-4 relative">
            <div className="flex items-center justify-between max-w-2xl mx-auto relative">
                <div className="absolute top-4 w-[70%] left-1/2 -translate-x-1/2 h-0.5 bg-gray-300"></div>

                <div className="relative flex flex-col items-center w-32">
                    <div className={getStepStyle("offer")}>
                        <span className={getTextStyle("offer")}>1</span>
                    </div>
                    <span className="text-sm mt-2 text-center">Sélection de l'offre</span>
                </div>

                <div className="relative flex flex-col items-center w-32">
                    <div className={getStepStyle("payment")}>
                        <span className={getTextStyle("payment")}>2</span>
                    </div>
                    <span className="text-sm mt-2 text-center">Paiement</span>
                </div>

                <div className="relative flex flex-col items-center w-32">
                    <div className={getStepStyle("confirmation")}>
                        <span className={getTextStyle("confirmation")}>3</span>
                    </div>
                    <span className="text-sm mt-2 text-center">Confirmation</span>
                </div>
            </div>
        </div>
    )
}